import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const options = {
    method: "GET",
    headers: {
        'Content-Type': 'application/json',
    }
};

function Modal({ user, set, member, role, setIndex = () => { } }) {

    const [memberData, setMemberData] = useState(member || {});
    const [clubs, setClubs] = useState([]);
    const [staff, setStaff] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (member) {
            //Make dates valid
            let end = null,
                start = null,
                birthdate = null;

            if (memberData?.member_end_date) {
                end = memberData.member_end_date;
                try {
                    end = new Date(parseInt(end)).toISOString().split('T')[0];
                } catch (e) {
                    end = null;
                }
            }

            if (memberData?.member_start_date) {
                start = memberData.member_start_date;
                try {
                    start = new Date(parseInt(start)).toISOString().split('T')[0];
                } catch (e) {
                    start = null;
                }
            }

            if (memberData?.birthdate) {
                birthdate = memberData.birthdate;
                try {
                    birthdate = new Date(parseInt(birthdate)).toISOString().split('T')[0];
                } catch (e) {
                    birthdate = null;
                }
            }

            setMemberData({ ...memberData, member_start_date: start, member_end_date: end, birthdate: birthdate });
        }

        fetch(process.env.REACT_APP_API_ENDPOINT + "/get-club-names", options)
            .then(data => data.json())
            .then(data => {
                setClubs(data.data);
            });

        if (role == "admin") {
            fetch(process.env.REACT_APP_API_ENDPOINT + "/get-staff", options)
                .then(data => data.json())
                .then(data => {
                    setStaff(Object.values(data.data));
                });
        } else {
            setMemberData({ ...memberData, referred_by: user.owner_firstname + " " + user.owner_lastname });
        }
    }, []);

    async function updateUser() {
        if (user) {
            let data = { ...memberData };

            let image = data?.["image"];
            if (image) {
                let extension = image["name"].split(".").at(-1);
                if (!["png", "jpg", "jpeg"].includes(extension)) {
                    image = null;
                    toast.error("Please be sure uploaded image format is PNG or JPG/JPEG");
                    setLoading(false);
                    return;
                }
                delete data["image"];
            }

            //Validate dates
            try {
                data["member_start_date"] = new Date(data["member_start_date"]).getTime().toString();
                if (data["member_end_date"]) data["member_end_date"] = new Date(data["member_end_date"]).getTime().toString();
                if (data["birthdate"]) data["birthdate"] = new Date(data["birthdate"]).getTime().toString();
            } catch (e) {
                toast.error("Please enter a valid date for member start and end date");
                setLoading(false);
                return;
            }

            //Validate integers
            try {
                if (data["grade"]) data["grade"] = parseInt(data["grade"]);
            } catch (e) {
                toast.error("Please enter a valid integer for grade and age");
                setLoading(false);
                return;
            }

            if (user) {
                const options = {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                        "access-token": localStorage.getItem("token")
                    },
                    body: JSON.stringify({
                        sid: data.id,
                        new: data
                    })
                };

                fetch(process.env.REACT_APP_API_ENDPOINT + "/update-member", options)
                    .then(data => data.json())
                    .then(_ => {
                        setLoading(true);
                        if (_?.status == 1) {
                            if (image) {
                                image = new File([image], data.id + ".png"); //Rename the file

                                const formData = new FormData();
                                formData.append("file", image);

                                const image_options = {
                                    method: "POST",
                                    body: formData
                                };

                                fetch(process.env.REACT_APP_API_ENDPOINT + "/upload-image", image_options)
                                    .then(data => data.json())
                                    .then(data => {
                                        if (data.status) {
                                            toast.success("Member updated successfully");
                                            setTimeout(() => { window.location.reload() }, 1000);
                                        } else {
                                            toast.error("Image cannot be uploaded");
                                            setLoading(false);
                                        }
                                    })
                            } else {
                                toast.success("Member updated successfully");
                                setTimeout(() => { window.location.reload() }, 1000);
                            }
                        }
                        else {
                            setLoading(false);
                            toast.error("An error occured");
                        }
                    })
            }
        }
    }

    async function addUser() {
        if (user) {
            let data = { ...memberData };

            let image = data?.["image"];
            if (image) {
                let extension = image["name"].split(".").at(-1);
                if (!["png", "jpg", "jpeg"].includes(extension)) {
                    image = null;
                    toast.error("Please be sure uploaded image format is PNG or JPG/JPEG");
                    setLoading(false);
                    return;
                }
                delete data["image"];
            }

            //Validate dates
            try {
                if (data["member_start_date"]) data["member_start_date"] = new Date(data["member_start_date"]).getTime().toString();
                else data["member_start_date"] = new Date().getTime().toString();
                if (data["member_end_date"]) data["member_end_date"] = new Date(data["member_end_date"]).getTime().toString();
                if (data["birthdate"]) data["birthdate"] = new Date(data["birthdate"]).getTime().toString();
            } catch (e) {
                toast.error("Please enter a valid dates");
                setLoading(false);
                return;
            }

            //Validate integers
            try {
                if (data["grade"]) data["grade"] = parseInt(data["grade"]);
            } catch (e) {
                toast.error("Please enter a valid integer for grade and age");
                setLoading(false);
                return;
            }

            const options = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    "access-token": localStorage.getItem("token")
                },
                body: JSON.stringify({
                    sid: data.id,
                    data: data
                })
            };

            fetch(process.env.REACT_APP_API_ENDPOINT + "/add-member", options)
                .then(data => data.json())
                .then(data => {
                    setLoading(true);
                    if (data?.status == 1) {
                        if (image) {
                            image = new File([image], data.id + ".png"); //Rename the file

                            const formData = new FormData();
                            formData.append("file", image);

                            const image_options = {
                                method: "POST",
                                body: formData
                            };

                            fetch(process.env.REACT_APP_API_ENDPOINT + "/upload-image", image_options)
                                .then(data => data.json())
                                .then(data => {
                                    toast.success("Member added successfully");
                                    if (data.status) {
                                        setTimeout(() => { window.location.reload() }, 1500);
                                    } else {
                                        toast.error("Image cannot be uploaded");
                                        setLoading(false);
                                    }
                                })
                        } else {
                            toast.success("Member added successfully");
                            setTimeout(() => { window.location.reload() }, 1500);
                        }
                    }
                    else {
                        setLoading(false);
                        toast.error(data?.msg || "An error occured");
                    }
                })
        }
    }

    //Dropdown
    const [clubNameAnchor, setClubNameAnchor] = useState(null);
    const clubNameOpen = Boolean(clubNameAnchor);
    const [clubRoleAnchor, setClubRoleAnchor] = useState(null);
    const clubRoleOpen = Boolean(clubRoleAnchor);
    const [memberStatusAnchor, setMemberStatusAnchor] = useState(null);
    const memberStatusOpen = Boolean(memberStatusAnchor);
    const [referredByAnchor, setReferredByAnchor] = useState(null);
    const referredByAnchorOpen = Boolean(referredByAnchor);

    return (
        <div className="modal" onClick={e => {
            if (e.currentTarget === e.target) {
                set(false);
                setIndex(null);
            }
        }}>
            <div className="modal-content">
                <button className="close" onClick={() => { set(false); setIndex(null); }}>✖</button>
                <h3>Record</h3>
                <div className='values'>
                    <div>
                        <p>First Name<span>*</span></p>
                        <div>
                            <input placeholder="Jason" defaultValue={member?.first_name || null} onChange={e => setMemberData({ ...memberData, first_name: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Last Name<span>*</span></p>
                        <div>
                            <input placeholder="Stark" defaultValue={member?.last_name || null} onChange={e => setMemberData({ ...memberData, last_name: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Birth Date<span>*</span></p>
                        <div>
                            <input placeholder="2023-07-22" value={memberData?.birthdate || ""} onChange={e => setMemberData({ ...memberData, birthdate: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Grade<span>*</span></p>
                        <div>
                            <input placeholder="8th" defaultValue={member?.grade || null} onChange={e => setMemberData({ ...memberData, grade: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Club Name<span>*</span></p>
                        <div>
                            <Menu
                                anchorEl={clubNameAnchor}
                                open={clubNameOpen}
                                onClose={() => setClubNameAnchor(null)}
                                id="clubname-menu"
                                className="data-dropdown"
                                MenuListProps={{ 'aria-labelledby': 'clubname-button' }}
                                aria-labelledby="clubname-button"
                            >
                                {clubs.map((d, i) => {
                                    return <MenuItem key={i} onClick={() => { setClubNameAnchor(null); setMemberData({ ...memberData, club_name: d }) }}>{d}</MenuItem>
                                })}
                            </Menu>
                            <Button
                                id="clubname-button"
                                aria-controls={clubNameOpen ? 'clubname-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={clubNameOpen ? 'true' : undefined}
                                onClick={(e) => setClubNameAnchor(e.currentTarget)}
                            >
                                <span>{memberData?.club_name || ""}</span>
                                <img src="/images/down.svg" />
                            </Button>
                        </div>
                    </div>
                    <div>
                        <p>Phone Number</p>
                        <div>
                            <input type='tel' placeholder="+0000000000" defaultValue={member?.phone_number || null} onChange={e => setMemberData({ ...memberData, phone_number: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Email<span>*</span></p>
                        <div>
                            <input type='email' placeholder="Current" defaultValue={member?.email || null} onChange={e => setMemberData({ ...memberData, email: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Member Description</p>
                        <div>
                            <textarea placeholder="Jason" defaultValue={member?.member_description || null} onChange={e => setMemberData({ ...memberData, member_description: e.target.value })}></textarea>
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Parents First Name</p>
                        <div>
                            <input placeholder="Jason" defaultValue={member?.parent_first_name || null} onChange={e => setMemberData({ ...memberData, parent_first_name: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Parents Last Name</p>
                        <div>
                            <input placeholder="Jason" defaultValue={member?.parent_last_name || null} onChange={e => setMemberData({ ...memberData, parent_last_name: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Parents Description</p>
                        <div>
                            <input placeholder="Jason" defaultValue={member?.parent_description || null} onChange={e => setMemberData({ ...memberData, parent_description: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Parent Phone Number</p>
                        <div>
                            <input type="tel" placeholder="+0000000000" defaultValue={member?.parent_phone_number || null} onChange={e => setMemberData({ ...memberData, parent_phone_number: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Parent Email</p>
                        <div>
                            <input type="email" placeholder="Current" defaultValue={member?.parent_email || null} onChange={e => setMemberData({ ...memberData, parent_email: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Address</p>
                        <div>
                            <input placeholder="Jason" defaultValue={member?.address || null} onChange={e => setMemberData({ ...memberData, address: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>School Name</p>
                        <div>
                            <input placeholder="Jason" defaultValue={member?.school_name || null} onChange={e => setMemberData({ ...memberData, school_name: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Club Role</p>
                        <div>
                            <Menu
                                anchorEl={clubRoleAnchor}
                                open={clubRoleOpen}
                                onClose={() => setClubRoleAnchor(null)}
                                id="clubrole-menu"
                                className="data-dropdown"
                                MenuListProps={{ 'aria-labelledby': 'clubrole-button' }}
                                aria-labelledby="clubrole-button"
                            >
                                <MenuItem onClick={() => { setClubRoleAnchor(null); setMemberData({ ...memberData, club_role: "Member" }) }}>Member</MenuItem>
                                <MenuItem onClick={() => { setClubRoleAnchor(null); setMemberData({ ...memberData, club_role: "President" }) }}>President</MenuItem>
                                <MenuItem onClick={() => { setClubRoleAnchor(null); setMemberData({ ...memberData, club_role: "Vice President" }) }}>Vice President</MenuItem>
                                <MenuItem onClick={() => { setClubRoleAnchor(null); setMemberData({ ...memberData, club_role: "Other" }) }}>Other</MenuItem>
                            </Menu>
                            <Button
                                id="clubrole-button"
                                aria-controls={clubRoleOpen ? 'clubrole-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={clubRoleOpen ? 'true' : undefined}
                                onClick={(e) => setClubRoleAnchor(e.currentTarget)}
                            >
                                <span>{memberData?.club_role || "Member"}</span>
                                <img src="/images/down.svg" />
                            </Button>
                        </div>
                    </div>
                    <div>
                        <p>Member Interests</p>
                        <div>
                            <input placeholder="Jason" defaultValue={member?.member_interests || null} onChange={e => setMemberData({ ...memberData, member_interests: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Member Status</p>
                        <div>
                            <Menu
                                anchorEl={memberStatusAnchor}
                                open={memberStatusOpen}
                                onClose={() => setMemberStatusAnchor(null)}
                                id="memberstatus-menu"
                                className="data-dropdown"
                                MenuListProps={{ 'aria-labelledby': 'memberstatus-button' }}
                                aria-labelledby="memberstatus-button"
                            >
                                <MenuItem onClick={() => { setMemberStatusAnchor(null); setMemberData({ ...memberData, member_status: "past" }) }}>past</MenuItem>
                                <MenuItem onClick={() => { setMemberStatusAnchor(null); setMemberData({ ...memberData, member_status: "current" }) }}>current</MenuItem>
                                <MenuItem onClick={() => { setMemberStatusAnchor(null); setMemberData({ ...memberData, member_status: "prospective" }) }}>prospective</MenuItem>
                            </Menu>
                            <Button
                                id="memberstatus-button"
                                aria-controls={memberStatusOpen ? 'memberstatus-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={memberStatusOpen ? 'true' : undefined}
                                onClick={(e) => setMemberStatusAnchor(e.currentTarget)}
                            >
                                <span>{memberData?.member_status || "current"}</span>
                                <img src="/images/down.svg" />
                            </Button>
                        </div>
                    </div>
                    <div>
                        <p>Member Start Date<span>*</span></p>
                        <div>
                            <input placeholder="2023-07-22" value={memberData?.member_start_date || new Date().toISOString().split('T')[0]} onChange={e => setMemberData({ ...memberData, member_start_date: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Member End Date</p>
                        <div>
                            <input placeholder="2023-07-22" value={memberData?.member_end_date || ""} onChange={e => setMemberData({ ...memberData, member_end_date: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    {role == "admin" && <div>
                        <p>Referred By</p>
                        <div>
                            <Menu
                                anchorEl={referredByAnchor}
                                open={referredByAnchorOpen}
                                onClose={() => setReferredByAnchor(null)}
                                id="referredby-menu"
                                className="data-dropdown"
                                MenuListProps={{ 'aria-labelledby': 'referredby-button' }}
                                aria-labelledby="referredby-button"
                            >
                                {staff.map((s, i) => {
                                    return <MenuItem key={i} onClick={() => { setReferredByAnchor(null); setMemberData({ ...memberData, referred_by: s.name }) }}>{s.name}</MenuItem>
                                })}
                            </Menu>
                            <Button
                                id="referredby-button"
                                aria-controls={referredByAnchorOpen ? 'referredby-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={referredByAnchorOpen ? 'true' : undefined}
                                onClick={(e) => setReferredByAnchor(e.currentTarget)}
                            >
                                <span>{memberData?.referred_by || "None"}</span>
                                <img src="/images/down.svg" />
                            </Button>
                        </div>
                    </div>}
                    <div>
                        <p>Events Attended</p>
                        <div>
                            <input placeholder="Jason" defaultValue={member?.events_attended || null} onChange={e => setMemberData({ ...memberData, events_attended: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Pictures</p>
                        <div>
                            <form>
                                <input type='file' name='file' id="file-btn" onChange={(e) => setMemberData({ ...memberData, image: e.target.files[0] })} style={{ display: "none" }}></input>
                                <label htmlFor='file-btn' className="picture-button"><img src="/images/plus-white.svg" /></label>
                            </form>
                        </div>
                    </div>
                </div>
                {loading ? (<CircularProgress />) : (
                    <div>
                        <button
                            className="close-button"
                            onClick={() => {
                                set(false);
                            }}
                        >Close</button>
                        <button
                            className="update"
                            onClick={async () => {
                                if (member) await updateUser();
                                else await addUser();
                            }}
                        >Update</button>
                    </div>
                )}
            </div>
        </div>
    )
}

function Modal3({ set, member }) {

    const [partners, setPartners] = useState([]);
    const [services, setServices] = useState([]);
    const [datas, setDatas] = useState({ member_id: member.id });

    useEffect(() => {
        fetch(process.env.REACT_APP_API_ENDPOINT + "/get-partners", options)
            .then(data => data.json())
            .then(data => {
                setPartners(data.data);
            });

        fetch(process.env.REACT_APP_API_ENDPOINT + "/get-services", options)
            .then(data => data.json())
            .then(data => {
                if (data?.data) setServices(data.data);
                else setServices([]);
            });
    }, []);

    function refer() {
        fetch(process.env.REACT_APP_API_ENDPOINT + "/refer-member", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "access-token": localStorage.getItem("token")
            },
            body: JSON.stringify({
                data: datas
            })
        })
        .then(data => data.json())
        .then(data => {
            if (data?.status == 1) {
                toast.success("Member successfully referred");
                setTimeout(() => { window.location.reload() }, 1000);
            } else {
                toast.error("An error occured");
            }
        });
    }

    //Dropdown
    const [partnerAnchor, setPartnerAnchor] = useState(null);
    const partnerOpen = Boolean(partnerAnchor);
    const [servicesAnchor, setServicesAnchor] = useState(null);
    const servicesOpen = Boolean(servicesAnchor);

    return (
        <div className="modal" onClick={e => {
            if (e.currentTarget === e.target) {
                set(false);
            }
        }}>
            <div className="modal-content">
                <button className="close" onClick={() => { set(false) }}>✖</button>
                <div className='values'>
                    <div>
                        <p>Partner</p>
                        <div>
                            <Menu
                                anchorEl={partnerAnchor}
                                open={partnerOpen}
                                onClose={() => setPartnerAnchor(null)}
                                id="partner-menu"
                                className="partner-dropdown"
                                MenuListProps={{ 'aria-labelledby': 'partner-button' }}
                                aria-labelledby="partner-button"
                            >
                                {partners?.map((d, i) => {
                                    return <MenuItem key={i} onClick={() => { setPartnerAnchor(null); setDatas({...datas, partner: d}) }}>{d}</MenuItem>
                                })}
                            </Menu>
                            <Button
                                id="partner-button"
                                aria-controls={partnerOpen ? 'partner-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={partnerOpen ? 'true' : undefined}
                                onClick={(e) => setPartnerAnchor(e.currentTarget)}
                            >
                                <span>{datas?.partner || "Select"}</span>
                                <img src="/images/down.svg" />
                            </Button>
                        </div>
                    </div>
                    <div>
                        <p>Services</p>
                        <div>
                            <Menu
                                anchorEl={servicesAnchor}
                                open={servicesOpen}
                                onClose={() => setServicesAnchor(null)}
                                id="services-menu"
                                className="services-dropdown"
                                MenuListProps={{ 'aria-labelledby': 'services-button' }}
                                aria-labelledby="services-button"
                            >
                                {services?.map((d, i) => {
                                    return <MenuItem key={i} onClick={() => { setServicesAnchor(null); setDatas({...datas, service: d}) }}>{d.service_name}</MenuItem>
                                })}
                            </Menu>
                            <Button
                                id="services-button"
                                aria-controls={servicesOpen ? 'services-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={servicesOpen ? 'true' : undefined}
                                onClick={(e) => setServicesAnchor(e.currentTarget)}
                            >
                                <span>{datas?.service?.service_name || "Select"}</span>
                                <img src="/images/down.svg" />
                            </Button>
                        </div>
                    </div>
                    <div>
                        <p>Contract Amount</p>
                        <div>
                            <input placeholder="10" defaultValue={datas?.contract_amount || null} onChange={e => setDatas({ ...datas, contract_amount: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                </div>
                <div>
                    <button
                        className="close-button"
                        onClick={() => {
                            set(false);
                        }}
                    >Close</button>
                    <button
                        className="update"
                        onClick={() => {
                            refer();
                        }}
                    >Refer</button>
                </div>
            </div>
        </div>
    )
}

export default function Members({ list, admin, role="admin" }) {

    const user = useSelector(state => state.user);

    const [datas, setData] = useState([]);
    const [modal, setModal] = useState(false); // <-- Adding/updating member
    // const [modal2, setModal2] = useState(false); // <-- View details modal
    const [modal3, setModal3] = useState(false); // <-- Make a referral modal
    const [memberIndex, setMemberIndex] = useState(null);
    const [lastMonthMembers, setLastMonthMembers] = useState(0);
    const [filter, setFilter] = useState("");

    useEffect(() => {
        const arr = [];
        for (let el of list) arr.push({
            fName: el.first_name,
            lName: el.last_name,
            email: el.email,
            id: el.id,
            status: el.member_status
        });
        setData(arr);

        calculateLastMonthMembers();
    }, [list]);

    function calculateLastMonthMembers() {
        let count = 0;
        if (list) {
            for (let m of list) {
                let signupDate = new Date(parseInt(m.member_start_date)).getTime();
                let diff = Math.ceil((new Date().getTime() - signupDate) / (1000 * 60 * 60 * 24));
                if (diff >= 30 && diff < 60) count++;
            }
        }

        setLastMonthMembers(count);
    }

    function deleteMember(index) {
        if (user) {
            const options = {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    "access-token": localStorage.getItem("token")
                },
                body: JSON.stringify({
                    sid: list[index].id
                })
            };

            fetch(process.env.REACT_APP_API_ENDPOINT + "/delete-member", options)
                .then(data => data.json())
                .then(data => {
                    if (data?.status == 1) {
                        toast.success("Member deleted successfully");
                        setTimeout(() => { window.location.reload() }, 1000);
                    }
                    else toast.error(data?.msg || "An error occured");
                })
        }
    }

    return (
        <div id="members">
            <div className="top">
                <div className="stat">
                    <div>
                        <p className="title">Total Members :</p>
                    </div>
                    <div>
                        <p className="value">{list?.length}</p>
                    </div>
                </div>
                <div className="stat">
                    <div>
                        <p className="title">Last Month :</p>
                    </div>
                    <div>
                        <p className="value">{lastMonthMembers}</p>
                        {/* <div className="percent negative"> 20%&nbsp;&nbsp;↓ </div> */}
                    </div>
                </div>
                <div className="search">
                    <div>
                        <img src="/images/search.svg" />
                        <input placeholder="Search" onChange={e => setFilter(e.target.value)} />
                    </div>
                </div>
            </div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th></th> {/* Blank for number section in tbody */}
                            <th className="empty-data"></th> {/* Blank for image in tbody */}
                            <th className='non-empty-data'>First Name</th>
                            <th>Last Name</th>
                            <th>
                                {(admin) &&
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                        <button onClick={() => setModal(true)}>Add New</button>
                                    </div>
                                }
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {datas.map((d, i) => {
                            if (filter) {
                                if (!((i + 1) + d.fName + d.lName + d.email).toLowerCase().includes(filter.toLowerCase())) return <></>;
                            }
                            if (d?.status !== "current") return <></>
                            return (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td className="member-td">
                                        <img className="member-image" src={"/images/uploads/" + d.id + ".png"} onError={(e) => { e.target.onError = null; e.target.src = "/images/uploads/default.png" }} />
                                    </td>
                                    <td>{d.fName}</td>
                                    <td>{d.lName}</td>
                                    <td>
                                        <div>
                                            {role == "admin" && <button
                                                className="details"
                                                onClick={() => {
                                                    setMemberIndex(i);
                                                    setModal3(true);
                                                }}
                                            >Make a Referral</button>}
                                            <button
                                                className="details"
                                                onClick={() => {
                                                    setMemberIndex(i);
                                                    setModal(true);
                                                }}
                                            >Update / View</button>
                                            {role == "admin" && <button
                                                className="trash"
                                                onClick={() => {
                                                    deleteMember(i);
                                                }}
                                            >
                                                <img src="/images/trash.svg" />
                                            </button>}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            {/* Modals --> */}
            {(modal && list?.[memberIndex]) &&
                <Modal
                    role={role}
                    user={user}
                    set={setModal}
                    member={list[memberIndex]}
                    setIndex={setMemberIndex}
                />
            }
            {(modal && !list?.[memberIndex]) && <Modal user={user} set={setModal} />}
            {modal3 && <Modal3 set={setModal3} member={list[memberIndex]} />}
        </div>
    )
}