import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";
import "./signup.scss";

export default function Login() {
    const [datas, setDatas] = useState({});
    const [clubs, setClubs] = useState([]);
    const [err, setErr] = useState();
    const [suc, setSuc] = useState();

    useEffect(() => {
        //Get all available clubs
        const options = {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        };

        fetch(process.env.REACT_APP_API_ENDPOINT + "/get-club-names", options)
            .then((data) => data.json())
            .then((data) => {
                if (data?.data) setClubs(data.data);
            });
    }, []);

    useEffect(() => {
        let image = datas?.["image"];
        let success = false;

        if (image) {
            let extension = image["name"].split(".").at(-1);
            if (!["png", "jpg", "jpeg"].includes(extension))
                toast.error(
                    "Please be sure uploaded image format is PNG or JPG/JPEG"
                );
            else if (image.size / 1024 / 1024 > 4)
                toast.error("Image cannot be larger than 4MB");
            else {
                success = true;
                toast.success("Image has been uploaded successfully");
            }
        }

        if (!success) {
            let obj = { ...datas };
            delete obj["image"];
            setDatas(image);
        }
    }, [datas?.image]);

    function register(e) {
        e.preventDefault();

        if (
            !(
                datas?.firstName &&
                datas?.lastName &&
                datas?.email &&
                datas?.club
            )
        ) {
            setErr("Please fill in all required fields");
        } else {
            let image = datas?.["image"];

            const options = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(datas),
            };

            fetch(
                process.env.REACT_APP_API_ENDPOINT + "/member-signup",
                options
            )
                .then((data) => data.json())
                .then((data) => {
                    if (data?.status === 1) {
                        if (image) {
                            image = new File([image], data.id + ".png"); //Rename the file

                            const formData = new FormData();
                            formData.append("file", image);

                            const image_options = {
                                method: "POST",
                                body: formData,
                            };

                            fetch(
                                process.env.REACT_APP_API_ENDPOINT +
                                    "/upload-image",
                                image_options
                            )
                                .then((data) => data.json())
                                .then((data) => {
                                    setSuc(
                                        "You've been successfully registered!"
                                    );
                                    setErr(null);
                                    toast.success(
                                        "You've been successfully registered!"
                                    );
                                    if (!data.status)
                                        toast.error("Image cannot be uploaded");
                                });
                        } else {
                            setSuc("You've been successfully registered!");
                            setErr(null);
                            toast.success(
                                "You've been successfully registered!"
                            );
                        }
                    } else if (data?.msg) {
                        setErr(data.msg);
                        toast.error(data.msg);
                    } else if (data?.status === 0) {
                        setErr("Please fill in all required fields");
                        toast.error("Please fill in all required fields");
                    }
                });
        }
    }

    //Dropdown
    const [clubAnchor, setClubAnchor] = useState(null);
    const clubOpen = Boolean(clubAnchor);

    return (
        <div className="signup-container">
            <h2 className="signup-title">
                Welcome to{" "}
                <span className="highlight" style={{ color: "#22d1ee" }}>
                    IvyRadar
                </span>
                <br /> Please register to join a club or attend an event
            </h2>
            <div id="signup">
                <div className="form-container">
                    <h1>Student Registration</h1>
                    <form>
                        {err && <span className="error">{err}</span>}
                        {suc && <span className="success">{suc}</span>}
                        <div className="section">
                            <h2>Student Information（学生信息）</h2>
                            <div>
                                <input
                                    class="red-asterisk"
                                    type="text"
                                    placeholder="First Name (名)"
                                    onChange={(e) =>
                                        setDatas({
                                            ...datas,
                                            firstName: e.target.value,
                                        })
                                    }
                                />
                                <input
                                    class="red-asterisk"
                                    type="text"
                                    placeholder="Last Name (姓)"
                                    onChange={(e) =>
                                        setDatas({
                                            ...datas,
                                            lastName: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div>
                                <input
                                    class="red-asterisk"
                                    type="text"
                                    placeholder="Grade (年级)"
                                    onChange={(e) =>
                                        setDatas({
                                            ...datas,
                                            grade: e.target.value,
                                        })
                                    }
                                />
                                <input
                                    class="red-asterisk"
                                    type="email"
                                    placeholder="Email (邮箱)"
                                    onChange={(e) =>
                                        setDatas({
                                            ...datas,
                                            email: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    placeholder="Country (国家)"
                                    onChange={(e) =>
                                        setDatas({
                                            ...datas,
                                            country: e.target.value,
                                        })
                                    }
                                />
                                <input
                                    type="text"
                                    placeholder="Address (地址)"
                                    onChange={(e) =>
                                        setDatas({
                                            ...datas,
                                            address: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div>
                                <Button
                                    id="club-button"
                                    aria-controls={
                                        clubOpen ? "club-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                        clubOpen ? "true" : undefined
                                    }
                                    onClick={(e) =>
                                        setClubAnchor(e.currentTarget)
                                    }
                                >
                                    <span>{datas?.club || "Club (选择俱乐部)"}</span>
                                    <img src="/images/down.svg" />
                                </Button>
                                <Menu
                                    anchorEl={clubAnchor}
                                    open={clubOpen}
                                    onClose={() => setClubAnchor(null)}
                                    id="club-menu"
                                    className="signup-dropdown"
                                    MenuListProps={{
                                        "aria-labelledby": "club-button",
                                    }}
                                    aria-labelledby="club-button"
                                >
                                    {clubs.map((club, index) => {
                                        return (
                                            <MenuItem
                                                key={index}
                                                onClick={() => {
                                                    setClubAnchor(null);
                                                    setDatas({
                                                        ...datas,
                                                        club: club,
                                                    });
                                                }}
                                            >
                                                {club}
                                            </MenuItem>
                                        );
                                    })}
                                </Menu>

                                <input
                                    type="text"
                                    placeholder="School (学校)"
                                    onChange={(e) =>
                                        setDatas({
                                            ...datas,
                                            school: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div>
                                <textarea
                                    maxLength={1024}
                                    placeholder="Briefly describe the student. Their Specialties, Hobbies etc. (个人描述：如果你想成为俱乐部成员) Examples (例子): https://club1.ivyradar.com/members"
                                    onChange={(e) =>
                                        setDatas({
                                            ...datas,
                                            description: e.target.value,
                                        })
                                    }
                                ></textarea>
                            </div>
                            <div className="picture-container">
                                <p>Pictures</p>
                                <div>
                                    <form>
                                        <input
                                            type="file"
                                            name="file"
                                            id="file-btn"
                                            onChange={(e) =>
                                                setDatas({
                                                    ...datas,
                                                    image: e.target.files[0],
                                                })
                                            }
                                            style={{ display: "none" }}
                                        ></input>
                                        <label
                                            htmlFor="file-btn"
                                            className="picture-button"
                                        >
                                            <img src="/images/plus-white.svg" />
                                        </label>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="section">
                            <h2>Parent Information（家长信息）</h2>
                            <div>
                                <input
                                    type="text"
                                    placeholder="First Name (名)"
                                    onChange={(e) =>
                                        setDatas({
                                            ...datas,
                                            parentFirstName: e.target.value,
                                        })
                                    }
                                />
                                <input
                                    type="text"
                                    placeholder="Last Name (姓)"
                                    onChange={(e) =>
                                        setDatas({
                                            ...datas,
                                            parentLastName: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div>
                                <input
                                    type="tel"
                                    placeholder="Phone No. (电话号码)"
                                    onChange={(e) =>
                                        setDatas({
                                            ...datas,
                                            parentPhone: e.target.value,
                                        })
                                    }
                                />
                                <input
                                    type="email"
                                    placeholder="Email (邮箱)"
                                    onChange={(e) =>
                                        setDatas({
                                            ...datas,
                                            parentEmail: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </div>
                        <button className="register-button" onClick={register}>
                            Register
                        </button>
                        <br />
                    </form>
                </div>
                <div className="image-container">
                    {/* <img src="/images/login-bg.svg" className="bg" />
                <div>
                    <img src="/images/signup-vector.svg" className="vector" />
                </div> */}
                </div>
            </div>
        </div>
    );
}
