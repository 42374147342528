import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { setUser } from './store/reducers/userReducer';
import { useDispatch } from 'react-redux';

//Page imports
import Login from './pages/login';
import Signup from './pages/signup';
import Dashboard from './pages/dashboard';

export default function App() {

	const dispatch = useDispatch();

	const [userState, setUserState] = useState(false);

	useEffect(() => {

		const token = localStorage.getItem("token");
		if (token) {
			const options = {
				method: "POST",
				headers: {
					'Content-Type': 'application/json',
					"access-token": token
				}
			};
	
			fetch(process.env.REACT_APP_API_ENDPOINT + "/check-login", options)
			.then(data => data.json())
			.then(data => {
				if (data.status == 1) {
					setUserState(true);
					dispatch(setUser(data.data));
				}
			})
		}

	}, []);

	return (
		<>
			<main>
				{ userState ? (
					<Routes>
						<Route path="/dashboard/*" element={<Dashboard />} />
						<Route path="*" element={<Dashboard />} />
					</Routes>
				) : (
					<Routes>
						<Route path="/admin" element={<Login />} />
						<Route path="*" element={<Signup />} />
					</Routes>
				)}
			</main>
		</>
	);
}