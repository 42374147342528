import { useState } from 'react';
import { Link } from 'react-router-dom';
import './nav.scss';

export default function DashboardNav({ path, role }) {

    const [toggle, setToggle] = useState(false);

    return (
        <>
        { !toggle && (
            <div id="sidenav-hidden">
                <button onClick={() => setToggle(!toggle)}><img src="/images/menu.png" /></button>
            </div>
        )}
        { toggle && (
            <div className="nav-bg" onClick={() => setToggle(false)}></div>
        )}
        <nav id="dashboard-nav" className={toggle ? "force-toggle" : undefined}>
            <h1 className='logo'>LOGO</h1>
            <div className="list">
                {role == "admin" && (
                    <>
                        <Link to="/dashboard" className={path === "/dashboard" ? "active" : undefined} onClick={() => setToggle(false)}>
                            <img src="/images/dashboard.svg" />
                            Dashboard
                        </Link>
                        <Link to="/dashboard/clubs" className={path === "/dashboard/clubs" ? "active" : undefined} onClick={() => setToggle(false)} >
                            <img src="/images/dashboard.svg" />
                            Clubs
                        </Link>
                        <Link to="/dashboard/members" className={path === "/dashboard/members" ? "active" : undefined} onClick={() => setToggle(false)} >
                            <img src="/images/members.svg" />
                            Members
                        </Link>
                        <Link to="/dashboard/events" className={path === "/dashboard/events" ? "active" : undefined} onClick={() => setToggle(false)} >
                            <img src="/images/events.svg" />
                            Events
                        </Link>
                        <Link to="/dashboard/staff" className={path === "/dashboard/staff" ? "active" : undefined} onClick={() => setToggle(false)} >
                            <img src="/images/staff.svg" />
                            Staff
                        </Link>
                        <Link to="/dashboard/partners" className={path === "/dashboard/partners" ? "active" : undefined} onClick={() => setToggle(false)} >
                            <img src="/images/partners.svg" />
                            Partners
                        </Link>
                        <Link to="/dashboard/services" className={path === "/dashboard/services" ? "active" : undefined} onClick={() => setToggle(false)} >
                            <img src="/images/services.svg" />
                            Services
                        </Link>
                        <Link to="/dashboard/service-tracking" className={path === "/dashboard/service-tracking" ? "active" : undefined} onClick={() => setToggle(false)} >
                            <img src="/images/service-tracking.svg" />
                            Service Tracking
                        </Link>
                        <Link to="/dashboard/transactions" className={path === "/dashboard/transactions" ? "active" : undefined} onClick={() => setToggle(false)} >
                            <img src="/images/transactions.svg" />
                            Transactions
                        </Link>
                    </>
                )}
                {role == "staff" && (
                    <>
                        <Link to="/dashboard" className={path === "/dashboard" ? "active" : undefined} onClick={() => setToggle(false)} >
                            <img src="/images/dashboard.svg" />
                            Dashboard
                        </Link>
                        <Link to="/dashboard/members" className={path === "/dashboard/members" ? "active" : undefined} onClick={() => setToggle(false)} >
                            <img src="/images/members.svg" />
                            Members
                        </Link>
                        <Link to="/dashboard/service-tracking" className={path === "/dashboard/service-tracking" ? "active" : undefined} onClick={() => setToggle(false)} >
                            <img src="/images/service-tracking.svg" />
                            Service Tracking
                        </Link>
                        <Link to="/dashboard/transactions" className={path === "/dashboard/transactions" ? "active" : undefined} onClick={() => setToggle(false)} >
                            <img src="/images/transactions.svg" />
                            Transactions
                        </Link>
                    </>
                )}
            </div>
        </nav>
        </>
    )
}