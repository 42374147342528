import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

function Modal({ user, set, service, setIndex = () => { } }) {

    let rendered = false;

    const [servicesData, setServicesData] = useState(service || {});
    const [serviceProviders, setServiceProviders] = useState([]);
    const [clubs, setClubs] = useState([]);
    const [services, setServices] = useState([]);
    const [members, setMembers] = useState([]);
    const [referrals, setReferrals] = useState({});
    const [staff, setStaff] = useState([]);

    useEffect(() => {
        if (service) {
            //Make dates valid
            let start = null;
            if (servicesData?.date) {
                start = servicesData.date;
                try {
                    start = new Date(parseInt(start)).toISOString().split('T')[0];
                } catch (e) {
                    start = null;
                }
            }

            setServicesData({ ...servicesData, date: start });
        }

        const options = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        };

        fetch(process.env.REACT_APP_API_ENDPOINT + "/get-service-names", options)
            .then(data => data.json())
            .then(data => {
                setServices(data.data);
            });

        fetch(process.env.REACT_APP_API_ENDPOINT + "/get-club-names", options)
            .then(data => data.json())
            .then(data => {
                let arr = [];
                for (let n of data.data) arr.push({club_name: n});
                setClubs(arr);
            });

        fetch(process.env.REACT_APP_API_ENDPOINT + "/get-staff", options)
            .then(data => data.json())
            .then(data => {
                console.log(data);
                setStaff(Object.values(data.data));
            });
    }, []);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_ENDPOINT + "/get-service-partner", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name: servicesData.service_name })
        })
        .then(data => data.json())
        .then(data => {
            let _ = data.data;
            let providers = [];
            _.forEach(i => {
                providers.push(i.service_provider?.split(", "));
            })
            setServiceProviders(providers);
        });
    }, [servicesData.service_name]);

    useEffect(() => {
        
        if (rendered) {
            // Clear old datas
            let services_data = {...servicesData};
            delete services_data?.["member_id"];
            delete services_data?.["member_name"];
            delete services_data?.["referred_by"];
            setServicesData(services_data);
        } else rendered = true;

        if (servicesData?.club_name) {
            fetch(process.env.REACT_APP_API_ENDPOINT + "/get-members-from-club", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    club: servicesData.club_name
                })
            })
                .then(data => data.json())
                .then(data => {
                    if (data.data?.length > 0) setMembers(data.data);
                });
        }
    }, [servicesData.club_name]);

    async function updateServices() {
        if (user) {
            let data = { ...servicesData };

            //Validate integers
            try {
                if (data?.["commission_ratio"]) data["commission_ratio"] = parseFloat(data["commission_ratio"]);
                if (data?.["service_price"]) data["service_price"] = parseFloat(data["service_price"]);
                if (data?.["contract_amount"]) data["contract_amount"] = parseFloat(data["contract_amount"]);
                if (data?.["recommender_comission"]) data["recommender_comission"] = parseFloat(data["recommender_comission"]);
            } catch (e) {
                toast.error("Please enter a valid decimal for commission ratio");
                return;
            }

            const options = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    "access-token": localStorage.getItem("token")
                },
                body: JSON.stringify({
                    sid: data.id,
                    new: data
                })
            };

            fetch(process.env.REACT_APP_API_ENDPOINT + "/update-service-tracking", options)
                .then(data => data.json())
                .then(data => {
                    if (data?.status == 1) {
                        toast.success("Service updated successfully");
                        setTimeout(() => { window.location.reload() }, 1000);
                    }
                    else toast.error("An error occured");
                })
        }
    }

    async function addServices() {
        if (user) {
            let data = { ...servicesData };

            //Validate numbers
            try {
                if (data?.["commission_ratio"]) data["commission_ratio"] = parseFloat(data["commission_ratio"]);
                if (data?.["service_price"]) data["service_price"] = parseFloat(data["service_price"]);
                if (data?.["contract_amount"]) data["contract_amount"] = parseFloat(data["contract_amount"]);
                if (data?.["recommender_comission"]) data["recommender_comission"] = parseFloat(data["recommender_comission"]);
            } catch (e) {
                toast.error("Please enter valid decimals");
                return;
            }

            const options = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    "access-token": localStorage.getItem("token")
                },
                body: JSON.stringify({
                    sid: data.id,
                    data: data
                })
            };

            fetch(process.env.REACT_APP_API_ENDPOINT + "/add-service-tracking", options)
                .then(data => data.json())
                .then(data => {
                    if (data?.status == 1) {
                        toast.success("Service added successfully");
                        setTimeout(() => { window.location.reload() }, 1000);
                    }
                    else toast.error(data?.msg || "An error occured");
                })
        }
    }

    //Dropdown
    const [membersAnchor, setMembersAnchor] = useState(null);
    const membersOpen = Boolean(membersAnchor);
    const [clubNameAnchor, setClubNameAnchor] = useState(null);
    const clubNameOpen = Boolean(clubNameAnchor);
    const [servicesAnchor, setServicesAnchor] = useState(null);
    const servicesOpen = Boolean(servicesAnchor);
    const [serviceStatusAnchor, setServiceStatusAnchor] = useState(null);
    const serviceStatusOpen = Boolean(serviceStatusAnchor);
    const [paymentReceivedAnchor, setPaymentReceivedAnchor] = useState(null);
    const paymentReceivedOpen = Boolean(paymentReceivedAnchor);
    const [serviceProviderAnchor, setServiceProviderAnchor] = useState(null);
    const serviceProviderOpen = Boolean(serviceProviderAnchor);
    const [referredByAnchor, setReferredByAnchor] = useState(null);
    const referredByOpen = Boolean(referredByAnchor);

    return (
        <div className="modal" onClick={e => {
            if (e.currentTarget === e.target) {
                set(false);
                setIndex(null);
            }
        }}>
            <div className="modal-content">
                <button className="close" onClick={() => { set(false); setIndex(null); }}>✖</button>
                <h3>Record</h3>
                <div className='values'>
                    <div>
                        <p>Club Name<span>*</span></p>
                        <div>
                            <Menu
                                anchorEl={clubNameAnchor}
                                open={clubNameOpen}
                                onClose={() => setClubNameAnchor(null)}
                                id="clubname-menu"
                                className="data-dropdown"
                                MenuListProps={{ 'aria-labelledby': 'clubname-button' }}
                                aria-labelledby="clubname-button"
                            >
                                {clubs.map((d, i) => {
                                    return <MenuItem key={i} onClick={() => { setClubNameAnchor(null); setServicesData({ ...servicesData, club_name: d.club_name }) }}>{d.club_name}</MenuItem>
                                })}
                            </Menu>
                            <Button
                                id="clubname-button"
                                aria-controls={clubNameOpen ? 'clubname-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={clubNameOpen ? 'true' : undefined}
                                onClick={(e) => setClubNameAnchor(e.currentTarget)}
                            >
                                <span>{servicesData?.club_name || ""}</span>
                                <img src="/images/down.svg" />
                            </Button>
                        </div>
                    </div>
                    <div>
                        <p>Member<span>*</span></p>
                        <div>
                            <Menu
                                anchorEl={membersAnchor}
                                open={membersOpen}
                                onClose={() => setMembersAnchor(null)}
                                id="members-menu"
                                className="data-dropdown"
                                MenuListProps={{ 'aria-labelledby': 'members-button' }}
                                aria-labelledby="members-button"
                            >
                                {members.map((s, i) => {
                                    return <MenuItem key={i} onClick={() => { setMembersAnchor(null); setServicesData({ ...servicesData, member_name: s.first_name + " " + s.last_name, member_id: s.id }) }}>{s.first_name + " " + s.last_name}</MenuItem>
                                })}
                            </Menu>
                            <Button
                                id="members-button"
                                aria-controls={membersOpen ? 'members-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={membersOpen ? 'true' : undefined}
                                onClick={(e) => setMembersAnchor(e.currentTarget)}
                            >
                                <span>{servicesData?.member_name || "None"}</span>
                                <img src="/images/down.svg" />
                            </Button>
                        </div>
                    </div>
                    <div>
                        <p>Service Name<span>*</span></p>
                        <div>
                            <Menu
                                anchorEl={servicesAnchor}
                                open={servicesOpen}
                                onClose={() => setServicesAnchor(null)}
                                id="services-menu"
                                className="data-dropdown"
                                MenuListProps={{ 'aria-labelledby': 'services-button' }}
                                aria-labelledby="services-button"
                            >
                                {services.map((s, i) => {
                                    return <MenuItem key={i} onClick={() => { setServicesAnchor(null); setServicesData({ ...servicesData, service_name: s }) }}>{s}</MenuItem>
                                })}
                            </Menu>
                            <Button
                                id="services-button"
                                aria-controls={servicesOpen ? 'services-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={servicesOpen ? 'true' : undefined}
                                onClick={(e) => setServicesAnchor(e.currentTarget)}
                            >
                                <span>{servicesData?.service_name || "None"}</span>
                                <img src="/images/down.svg" />
                            </Button>
                        </div>
                    </div>
                    <div>
                        <p>Service Providers<span>*</span></p>
                        <div>
                            <Menu
                                anchorEl={serviceProviderAnchor}
                                open={serviceProviderOpen}
                                onClose={() => setServiceProviderAnchor(null)}
                                id="serviceprovider-menu"
                                className="data-dropdown"
                                MenuListProps={{ 'aria-labelledby': 'serviceprovider-button' }}
                                aria-labelledby="serviceprovider-button"
                            >
                                {serviceProviders.map((s, i) => {
                                    return <MenuItem key={i} onClick={() => { setServiceProviderAnchor(null); setServicesData({ ...servicesData, service_provider: s }) }}>{s}</MenuItem>
                                })}
                            </Menu>
                            <Button
                                id="serviceprovider-button"
                                aria-controls={serviceProviderOpen ? 'serviceprovider-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={serviceProviderOpen ? 'true' : undefined}
                                onClick={(e) => setServiceProviderAnchor(e.currentTarget)}
                            >
                                <span>{servicesData?.service_provider || ""}</span>
                                <img src="/images/down.svg" />
                            </Button>
                        </div>
                    </div>
                    <div>
                        <p>Service Price</p>
                        <div>
                            <input placeholder="12" defaultValue={service?.service_price || ""} onChange={e => setServicesData({ ...servicesData, service_price: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Comission Ratio</p>
                        <div>
                            <input placeholder="12" defaultValue={service?.commission_ratio || ""} onChange={e => setServicesData({ ...servicesData, commission_ratio: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Comission to Recommender</p>
                        <div>
                            <input placeholder="12" defaultValue={service?.recommender_comission || ""} onChange={e => setServicesData({ ...servicesData, recommender_comission: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Service Status</p>
                        <div>
                            <Menu
                                anchorEl={serviceStatusAnchor}
                                open={serviceStatusOpen}
                                onClose={() => setServiceStatusAnchor(null)}
                                id="servicestatus-menu"
                                className="data-dropdown"
                                MenuListProps={{ 'aria-labelledby': 'servicestatus-button' }}
                                aria-labelledby="servicestatus-button"
                            >
                                <MenuItem onClick={() => { setServiceStatusAnchor(null); setServicesData({ ...servicesData, service_status: "Referred" }) }}>Referred</MenuItem>
                                <MenuItem onClick={() => { setServiceStatusAnchor(null); setServicesData({ ...servicesData, service_status: "Received" }) }}>Received</MenuItem>
                            </Menu>
                            <Button
                                id="servicestatus-button"
                                aria-controls={serviceStatusOpen ? 'servicestatus-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={serviceStatusOpen ? 'true' : undefined}
                                onClick={(e) => setServiceStatusAnchor(e.currentTarget)}
                            >
                                <span>{servicesData?.service_status || "None"}</span>
                                <img src="/images/down.svg" />
                            </Button>
                        </div>
                    </div>
                    <div>
                        <p>Referral Progress</p>
                        <div>
                            <input placeholder="Jason" defaultValue={service?.referral_progress_description || ""} onChange={e => setServicesData({ ...servicesData, referral_progress_description: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Contract Amount</p>
                        <div>
                            <input placeholder="12" defaultValue={service?.contract_amount || ""} onChange={e => setServicesData({ ...servicesData, contract_amount: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Payment Received</p>
                        <div>
                            <Menu
                                anchorEl={paymentReceivedAnchor}
                                open={paymentReceivedOpen}
                                onClose={() => setPaymentReceivedAnchor(null)}
                                id="paymentreceived-menu"
                                className="data-dropdown"
                                MenuListProps={{ 'aria-labelledby': 'paymentreceived-button' }}
                                aria-labelledby="paymentreceived-button"
                            >
                                <MenuItem onClick={() => { setPaymentReceivedAnchor(null); setServicesData({ ...servicesData, payment_received: 1 }) }}>Received</MenuItem>
                                <MenuItem onClick={() => { setPaymentReceivedAnchor(null); setServicesData({ ...servicesData, payment_received: 0 }) }}>Not Received</MenuItem>
                            </Menu>
                            <Button
                                id="paymentreceived-button"
                                aria-controls={paymentReceivedOpen ? 'paymentreceived-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={paymentReceivedOpen ? 'true' : undefined}
                                onClick={(e) => setPaymentReceivedAnchor(e.currentTarget)}
                            >
                                <span>{servicesData?.payment_received ? "Received" : "Not Received"}</span>
                                <img src="/images/down.svg" />
                            </Button>
                        </div>
                    </div>
                    <div>
                        <p>Referred By</p>
                        <div>
                            <Menu
                                anchorEl={referredByAnchor}
                                open={referredByOpen}
                                onClose={() => setReferredByAnchor(null)}
                                id="referredby-menu"
                                className="data-dropdown"
                                MenuListProps={{ 'aria-labelledby': 'referredby-button' }}
                                aria-labelledby="referredby-button"
                            >
                                {staff.map((d, i) => {
                                    return <MenuItem key={i} onClick={() => { setReferredByAnchor(null); setServicesData({ ...servicesData, referred_by: d.name }) }}>{d.name}</MenuItem>
                                })}
                            </Menu>
                            <Button
                                id="referredby-button"
                                aria-controls={referredByOpen ? 'referredby-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={referredByOpen ? 'true' : undefined}
                                onClick={(e) => setReferredByAnchor(e.currentTarget)}
                            >
                                <span>{servicesData?.referred_by || ""}</span>
                                <img src="/images/down.svg" />
                            </Button>
                        </div>
                    </div>
                </div>
                <button
                    className="update"
                    onClick={async () => {
                        if (service) await updateServices();
                        else await addServices();
                    }}
                >Update</button>
            </div>
        </div>
    )
}

export default function ServiceTracking({ list, admin, role="admin" }) {

    const user = useSelector(state => state.user);

    const [datas, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [serviceTrackingIndex, setServiceTrackingIndex] = useState(null);
    const [filter, setFilter] = useState("");
    const [users, setUsers] = useState({});

    useEffect(() => {
        const arr = [];
        for (let el of list) arr.push({
            name: el.service_name,
            desc: el.referral_progress_description,
            ratio: el.commission_ratio,
            member_name: el.member_name,
            member_id: el.member_id
        });
        setData(arr);

        // Match overall user data with user id
        fetch(process.env.REACT_APP_API_ENDPOINT + "/get-members", {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(data => data.json())
            .then(data => {
                data = data.data;
                let obj = {};
                for (let member of data) obj[member.id] = member;
                setUsers(obj);
            })
    }, [list]);

    function deleteService(index) {
        if (user) {
            const options = {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    "access-token": localStorage.getItem("token")
                },
                body: JSON.stringify({
                    sid: list[index].id
                })
            };

            fetch(process.env.REACT_APP_API_ENDPOINT + "/delete-service-tracking", options)
                .then(data => data.json())
                .then(data => {
                    if (data?.status == 1) {
                        toast.success("Service deleted successfully");
                        setTimeout(() => { window.location.reload() }, 1000);
                    }
                    else toast.error(data?.msg || "An error occured");
                })
        }
    }

    return (
        <div id="trackings">
            <div className="top">
                <div className="stat">
                    <div>
                        <p className="title">Total Tracking Services :</p>
                    </div>
                    <div>
                        <p className="value">{list?.length}</p>
                    </div>
                </div>
                <div className="search">
                    <div>
                        <img src="/images/search.svg" />
                        <input placeholder="Search" onChange={e => setFilter(e.target.value)} />
                    </div>
                </div>
            </div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th></th> {/* Blank for number section in tbody */}
                            <th>Name</th>
                            <th>Description</th>
                            <th>Member Name</th>
                            <th>
                                {(admin && role == "admin") &&
                                    <>
                                        <button onClick={() => setModal(true)}>Add New</button>
                                    </>
                                }
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {datas.map((d, i) => {
                            if (filter) {
                                if (!((i + 1) + " " + d.name + d.desc + d.ratio + d.member_name + d.member_id + users?.[d.member_id]?.email + users?.[d.member_id]?.phone_number).toLowerCase().includes(filter.toLowerCase())) return <></>;
                            }
                            return (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{d.name}</td>
                                    <td>{d.desc}</td>
                                    <td>{d.member_name}</td>
                                    <td>
                                        <div>
                                            <button
                                                className="details"
                                                onClick={() => {
                                                    setServiceTrackingIndex(i);
                                                    setModal(true);
                                                }}
                                            >Details</button>
                                            <button
                                                className="trash"
                                                onClick={() => {
                                                    deleteService(i);
                                                }}
                                            >
                                                <img src="/images/trash.svg" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            {(modal && role == "admin" && list?.[serviceTrackingIndex]) &&
                <Modal
                    user={user}
                    set={setModal}
                    service={list[serviceTrackingIndex]}
                    setIndex={setServiceTrackingIndex}
                />
            }
            {(modal && role == "admin" && !list?.[serviceTrackingIndex]) && <Modal user={user} set={setModal} />}
        </div>
    )
}