import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

function Modal({ user, set, transactions, setIndex = () => {} }) {

    const [servicesData, setServicesData] = useState(transactions || {});
    const [staff, setStaff] = useState([]);
    const [partners, setPartners] = useState([]);

    useEffect(() => {
        if (transactions) {
            //Make dates valid
            let start = null;
            if (servicesData?.date) {
                start = servicesData.date;
                try {
                    start = new Date(parseInt(start)).toISOString().split('T')[0];
                } catch(e) {
                    start = null;
                }
            }

            setServicesData({...servicesData, date: start});
        }

        const options = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        };

        fetch(process.env.REACT_APP_API_ENDPOINT + "/get-staff", options)
            .then(data => data.json())
            .then(data => {
                setStaff(Object.values(data.data));
            });

        fetch(process.env.REACT_APP_API_ENDPOINT + "/get-partners", options)
            .then(data => data.json())
            .then(data => {
                setPartners(data.data);
            });
    }, []);

    async function updateServices() {
        if (user) {
            let data = {...servicesData};

            //Validate dates
            try {
                if (data["date"]) data["date"] = new Date(data["date"]).getTime().toString();
                else data["date"] = new Date().getTime().toString();
            } catch (e) {
                toast.error("Please enter a valid dates");
                return;
            }

            //Validate integers
            try {
                if (data["amount"]) data["amount"] = parseInt(data["amount"]);
            } catch(e) {
                toast.error("Please enter a valid decimal for amount");
                return;
            }

            const options = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    "access-token": localStorage.getItem("token")
                },
                body: JSON.stringify({
                    sid: data.id,
                    new: data
                })
            };
    
            fetch(process.env.REACT_APP_API_ENDPOINT + "/update-transaction", options)
            .then(data => data.json())
            .then(data => {
                if (data?.status == 1) {
                    toast.success("Transaction updated successfully");
                    setTimeout(() => { window.location.reload() }, 1000);
                }
                else toast.error("An error occured");
            })
        }
    }

    async function addServices() {
        if (user) {
            let data = {...servicesData};
            
            //Validate dates
            try {
                if (data["date"]) data["date"] = new Date(data["date"]).getTime().toString();
                else data["date"] = new Date().getTime().toString();
            } catch (e) {
                toast.error("Please enter a valid dates");
                return;
            }

            //Validate integers
            try {
                if (data["amount"]) data["amount"] = parseInt(data["amount"]);
            } catch(e) {
                toast.error("Please enter a valid decimal for amount");
                return;
            }

            const options = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    "access-token": localStorage.getItem("token")
                },
                body: JSON.stringify({
                    sid: data.id,
                    data: data
                })
            };
    
            fetch(process.env.REACT_APP_API_ENDPOINT + "/add-transaction", options)
            .then(data => data.json())
            .then(data => {
                if (data?.status == 1) {
                    toast.success("Transaction added successfully");
                    setTimeout(() => { window.location.reload() }, 1000);
                }
                else toast.error(data?.msg || "An error occured");
            })
        }
    }

    const [typeAnchor, setTypeAnchor] = useState(null);
    const typeOpen = Boolean(typeAnchor);
    const [fromAnchor, setFromAnchor] = useState(null);
    const fromOpen = Boolean(fromAnchor);

    return (
        <div className="modal" onClick={e => {
            if (e.currentTarget === e.target) {
                set(false);
                setIndex(null);
            }
        }}>
            <div className="modal-content">
                <button className="close" onClick={() => { set(false); setIndex(null); }}>✖</button>
                <h3>Record</h3>
                <div className='values center'>
                    <div>
                        <p>Type<span>*</span></p>
                        <div>
                            <Menu
                                anchorEl={typeAnchor}
                                open={typeOpen}
                                onClose={() => setTypeAnchor(null)}
                                id="type-menu"
                                className="data-dropdown"
                                MenuListProps={{ 'aria-labelledby': 'type-button' }}
                                aria-labelledby="type-button"
                            >
                                <MenuItem onClick={() => { setTypeAnchor(null); setServicesData({ ...servicesData, transaction_name: "Income received from Partner" }) }}>Income received from Partner</MenuItem>
                                <MenuItem onClick={() => { setTypeAnchor(null); setServicesData({ ...servicesData, transaction_name: "Cost paid to vendor" }) }}>Cost paid to vendor</MenuItem>
                                <MenuItem onClick={() => { setTypeAnchor(null); setServicesData({ ...servicesData, transaction_name: "Comission paid to staff" }) }}>Comission paid to staff</MenuItem>
                                <MenuItem onClick={() => { setTypeAnchor(null); setServicesData({ ...servicesData, transaction_name: "Compensation Paid to staff" }) }}>Compensation Paid to staff</MenuItem>
                            </Menu>
                            <Button
                                id="type-button"
                                aria-controls={typeOpen ? 'type-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={typeOpen ? 'true' : undefined}
                                onClick={(e) => setTypeAnchor(e.currentTarget)}
                            >
                                <span>{servicesData?.transaction_name || ""}</span>
                                <img src="/images/down.svg" />
                            </Button>
                        </div>
                    </div>
                    <div>
                        <p>From or To</p>
                        <div>
                            <Menu
                                anchorEl={fromAnchor}
                                open={fromOpen}
                                onClose={() => setFromAnchor(null)}
                                id="from-menu"
                                className="data-dropdown"
                                MenuListProps={{ 'aria-labelledby': 'from-button' }}
                                aria-labelledby="from-button"
                            >
                                {staff.map((d, i) => {
                                    return <MenuItem key={i} onClick={() => { setFromAnchor(null); setServicesData({ ...servicesData, from_or_to: d.name }) }}>{d.name}</MenuItem>
                                })}
                                {partners.map((d, i) => {
                                    return <MenuItem key={i} onClick={() => { setFromAnchor(null); setServicesData({ ...servicesData, from_or_to: d }) }}>{d}</MenuItem>
                                })}
                            </Menu>
                            <Button
                                id="from-button"
                                aria-controls={fromOpen ? 'from-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={fromOpen ? 'true' : undefined}
                                onClick={(e) => setFromAnchor(e.currentTarget)}
                            >
                                <span>{servicesData?.from_or_to || ""}</span>
                                <img src="/images/down.svg" />
                            </Button>
                        </div>
                    </div>
                    <div>
                        <p>Amount</p>
                        <div>
                            <input placeholder="Jason" defaultValue={transactions?.amount || ""} onChange={e => setServicesData({...servicesData, amount: e.target.value})} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Description</p>
                        <div>
                            <input placeholder="Jason" defaultValue={transactions?.description || ""} onChange={e => setServicesData({...servicesData, description: e.target.value})} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Date</p>
                        <div>
                            <input placeholder="2023-07-22" value={servicesData?.date || new Date().toISOString().split('T')[0]} onChange={e => setServicesData({ ...servicesData, date: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                </div>
                <button
                    className="update"
                    onClick={async () => {
                        if (transactions) await updateServices();
                        else await addServices();
                    }}
                >Update</button>
            </div>
        </div>
    )
}

export default function Transactions({ list, admin, role="admin" }) {

    const user = useSelector(state => state.user);

    const [datas, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [transactionIndex, setTransactionIndex] = useState(null);
    const [lastMonthTransactions, setLastMonthTransaction] = useState(0);
    const [filter, setFilter] = useState("");

    useEffect(() => {
        const arr = [];
        for (let el of list) arr.push({
            name: el.transaction_name,
            desc: el.description,
            date: el.date
        });
        setData(arr);

        calculateLastMonthTransactions();
    }, [list]);

    function deleteService(index) {
        if (user) {
            const options = {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    "access-token": localStorage.getItem("token")
                },
                body: JSON.stringify({
                    sid: list[index].id
                })
            };
    
            fetch(process.env.REACT_APP_API_ENDPOINT + "/delete-transaction", options)
            .then(data => data.json())
            .then(data => {
                if (data?.status == 1) {
                    toast.success("Transaction deleted successfully");
                    setTimeout(() => { window.location.reload() }, 1000);
                }
                else toast.error(data?.msg || "An error occured");
            })
        }
    }

    function calculateLastMonthTransactions() {
        let count = 0;
        if (list) {
            for (let m of list) {
                let transactionDate = new Date(parseInt(m.date)).getTime();
                let diff = Math.ceil((new Date().getTime() - transactionDate) / (1000 * 60 * 60 * 24));
                if (diff >= 30 && diff < 60) count++;
            }
        }

        setLastMonthTransaction(count);
    }

    return (
        <div id="transactions">
            <div className="top">
                <div className="stat">
                    <div>
                        <p className="title">Total Transactions :</p>
                    </div>
                    <div>
                        <p className="value">{list?.length}</p>
                    </div>
                </div>
                <div className="stat">
                    <div>
                        <p className="title">Last Month :</p>
                    </div>
                    <div>
                        <p className="value">{lastMonthTransactions}</p>
                    </div>
                </div>
                <div className="search">
                    <div>
                        <img src="/images/search.svg" />
                        <input placeholder="Search" onChange={e => setFilter(e.target.value)} />
                    </div>
                </div>
            </div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th></th> {/* Blank for number section in tbody */}
                            <th>Name</th>
                            <th>Description</th>
                            <th>Date</th>
                            <th>
                                { (admin && role == "admin") &&
                                    <>
                                        <button onClick={() => setModal(true)}>Add New</button>
                                    </>
                                }
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {datas.map((d, i) => {
                            if (filter) {
                                if (!((i + 1) + d.name + d.desc + d.date).toLowerCase().includes(filter.toLowerCase())) return <></>;
                            }
                            return (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{d.name}</td>
                                    <td style={{ minWidth: "300px" }}>{d.desc}</td>
                                    <td>{new Date(parseInt(d.date) || new Date().getTime()).toLocaleDateString("en-EN")}</td>
                                    {role == "admin" && <td>
                                        <div>
                                            <button
                                                className="details"
                                                onClick={() => {
                                                    setTransactionIndex(i);
                                                    setModal(true);
                                                }}
                                            >Details</button>
                                            <button 
                                                className="trash"
                                                onClick={() => {
                                                    deleteService(i);
                                                }}
                                            >
                                                <img src="/images/trash.svg" />
                                            </button>
                                        </div>
                                    </td>}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            { (modal && role == "admin" && list?.[transactionIndex]) &&
                <Modal
                    user={user}
                    set={setModal}
                    transactions={list[transactionIndex]}
                    setIndex={setTransactionIndex}
                />
            }
            { (modal && role == "admin" && !list?.[transactionIndex]) && <Modal user={user} set={setModal} /> }
        </div>
    )
}