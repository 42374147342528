import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

function Modal({ user, set, partner, setIndex = () => {} }) {

    const [partnerData, setPartnerData] = useState(partner || {});

    useEffect(() => {
        if (partner) {
            //Make dates valid
            let start = null;
            if (partnerData?.date) {
                start = partnerData.date;
                try {
                    start = new Date(parseInt(start)).toISOString().split('T')[0];
                } catch(e) {
                    start = null;
                }
            }

            setPartnerData({...partnerData, date: start});
        }
    }, []);

    async function updatePartner() {
        if (user) {
            let data = {...partnerData};
            const options = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    "access-token": localStorage.getItem("token")
                },
                body: JSON.stringify({
                    sid: data.id,
                    new: data
                })
            };
    
            fetch(process.env.REACT_APP_API_ENDPOINT + "/update-partner", options)
            .then(data => data.json())
            .then(data => {
                if (data?.status == 1) {
                    toast.success("Partner updated successfully");
                    setTimeout(() => { window.location.reload() }, 1000);
                }
                else toast.error("An error occured");
            })
        }
    }

    async function addPartner() {
        if (user) {
            let data = {...partnerData};
            data["date"] = new Date().getTime().toString();

            const options = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    "access-token": localStorage.getItem("token")
                },
                body: JSON.stringify({
                    sid: data.id,
                    data: data
                })
            };
    
            fetch(process.env.REACT_APP_API_ENDPOINT + "/add-partner", options)
            .then(data => data.json())
            .then(data => {
                if (data?.status == 1) {
                    toast.success("Partner added successfully");
                    setTimeout(() => { window.location.reload() }, 1000);
                }
                else toast.error(data?.msg || "An error occured");
            })
        }
    }

    return (
        <div className="modal" onClick={e => {
            if (e.currentTarget === e.target) {
                set(false);
                setIndex(null);
            }
        }}>
            <div className="modal-content">
                <button className="close" onClick={() => { set(false); setIndex(null); }}>✖</button>
                <h3>Record</h3>
                <div className='values'>
                    <div>
                        <p>Partners Name<span>*</span></p>
                        <div>
                            <input placeholder="Jason" defaultValue={partner?.partner_name || ""} onChange={e => setPartnerData({...partnerData, partner_name: e.target.value})} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Partners Description</p>
                        <div>
                            <input placeholder="Jason" defaultValue={partner?.partner_description || ""} onChange={e => setPartnerData({...partnerData, partner_description: e.target.value})} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Contact Name<span>*</span></p>
                        <div>
                            <input placeholder="Jason" defaultValue={partner?.contact_name || ""} onChange={e => setPartnerData({...partnerData, contact_name: e.target.value})} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Email</p>
                        <div>
                            <input type="email" placeholder="Jason" defaultValue={partner?.email || ""} onChange={e => setPartnerData({...partnerData, email: e.target.value})} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Address</p>
                        <div>
                            <input placeholder="Jason" defaultValue={partner?.address || ""} onChange={e => setPartnerData({...partnerData, address: e.target.value})} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Relation Description</p>
                        <div>
                            <textarea placeholder="Jason" defaultValue={partner?.relationship_description || ""} onChange={e => setPartnerData({...partnerData, relationship_description: e.target.value})}></textarea>
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Services</p>
                        <div>
                            <input placeholder="Jason" defaultValue={partner?.services || ""} onChange={e => setPartnerData({...partnerData, services: e.target.value})} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                </div>
                <button
                    className="update"
                    onClick={async () => {
                        if (partner) await updatePartner();
                        else await addPartner();
                    }}
                >Update</button>
            </div>
        </div>
    )
}

export default function Partners({ list, admin }) {

    const user = useSelector(state => state.user);

    const [datas, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [partnerIndex, setPartnerIndex] = useState(null);
    const [lastMonthPartners, setLastMonthPartners] = useState(0);
    const [filter, setFilter] = useState("");

    useEffect(() => {
        const arr = [];
        for (let el of list) arr.push({
            name: el.partner_name,
            contact: el.contact_name,
        });
        setData(arr);

        calculateLastMonthPartners();
    }, [list]);

    function calculateLastMonthPartners() {
        let count = 0;
        if (list) {
            for (let m of list) {
                let partnerDate = new Date(parseInt(m.date)).getTime();
                let diff = Math.ceil((new Date().getTime() - partnerDate) / (1000 * 60 * 60 * 24));
                if (diff >= 30 && diff < 60) count++;
            }
        }

        setLastMonthPartners(count);
    }

    function deletePartner(index) {
        if (user) {
            const options = {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    "access-token": localStorage.getItem("token")
                },
                body: JSON.stringify({
                    sid: list[index].id
                })
            };
    
            fetch(process.env.REACT_APP_API_ENDPOINT + "/delete-partner", options)
            .then(data => data.json())
            .then(data => {
                if (data?.status == 1) {
                    toast.success("Partner deleted successfully");
                    setTimeout(() => { window.location.reload() }, 1000);
                }
                else toast.error(data?.msg || "An error occured");
            })
        }
    }

    return (
        <div id="partners">
            <div className="top">
                <div className="stat">
                    <div>
                        <p className="title">Total Partners :</p>
                    </div>
                    <div>
                        <p className="value">{list?.length}</p>
                    </div>
                </div>
                <div className="stat">
                    <div>
                        <p className="title">Last Month :</p>
                    </div>
                    <div>
                        <p className="value">{lastMonthPartners}</p>
                    </div>
                </div>
                <div className="search">
                    <div>
                        <img src="/images/search.svg" />
                        <input placeholder="Search" onChange={e => setFilter(e.target.value)} />
                    </div>
                </div>
            </div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th></th> {/* Blank for number section in tbody */}
                            <th>Partner Name</th>
                            <th>Contact Name</th>
                            <th>
                                { admin &&
                                    <>
                                        <button onClick={() => setModal(true)}>Add New</button>
                                    </>
                                }
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {datas.map((d, i) => {
                            if (filter) {
                                if (!((i + 1) + d.name + d.contact).toLowerCase().includes(filter.toLowerCase())) return <></>;
                            }
                            return (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{d.name}</td>
                                    <td>{d.contact}</td>
                                    <td>
                                        <div>
                                            <button
                                                className="details"
                                                onClick={() => {
                                                    setPartnerIndex(i);
                                                    setModal(true);
                                                }}
                                            >Details</button>
                                            <button 
                                                className="trash"
                                                onClick={() => {
                                                    deletePartner(i);
                                                }}
                                            >
                                                <img src="/images/trash.svg" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            { (modal && list?.[partnerIndex]) &&
                <Modal
                    user={user}
                    set={setModal}
                    partner={list[partnerIndex]}
                    setIndex={setPartnerIndex}
                />
            }
            { (modal && !list?.[partnerIndex]) && <Modal user={user} set={setModal} /> }
        </div>
    )
}