import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

function Modal({ user, set, event, setIndex = () => { } }) {

    const [eventData, setEventData] = useState(event || {});
    const [eventAttendees, setEventAttendees] = useState([]);
    const [clubs, setClubs] = useState([]);

    useEffect(() => {
        if (event) {
            //Make dates valid
            let start = null;
            if (eventData?.event_time) {
                start = eventData.event_time;
                try {
                    start = new Date(parseInt(start)).toISOString().split('T')[0];
                } catch (e) {
                    start = null;
                }
            }

            setEventData({ ...eventData, event_time: start });
        }

        const options = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        };

        fetch(process.env.REACT_APP_API_ENDPOINT + "/get-club-names", options)
            .then(data => data.json())
            .then(data => {
                setClubs(data.data);
            });

        if (event) {
            fetch(process.env.REACT_APP_API_ENDPOINT + "/event-attendees", {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    "access-token": localStorage.getItem("token")
                },
                body: JSON.stringify({
                    name: eventData.event_name
                })
            })
                .then(data => data.json())
                .then(data => {
                    if (data?.status == 1) setEventAttendees(data.data);
                });
        }
    }, []);

    async function updateEvent() {
        //Validate dates
        let data = { ...eventData };
        try {
            data["event_time"] = new Date(data["event_time"]).getTime().toString();
        } catch (e) {
            toast.error("Please enter a valid date for event time");
            return;
        }

        if (user) {
            const options = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    "access-token": localStorage.getItem("token")
                },
                body: JSON.stringify({
                    sid: data.id,
                    new: data
                })
            };

            fetch(process.env.REACT_APP_API_ENDPOINT + "/update-event", options)
                .then(data => data.json())
                .then(data => {
                    if (data?.status == 1) {
                        toast.success("Event updated successfully");
                        setTimeout(() => { window.location.reload() }, 1000);
                    }
                    else toast.error("An error occured");
                })
        }
    }

    async function addEvent() {
        if (user) {
            let data = { ...eventData };
            if (!data?.event_time) data["event_time"] = new Date().getTime().toString();

            const options = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    "access-token": localStorage.getItem("token")
                },
                body: JSON.stringify({
                    sid: data.id,
                    data: data
                })
            };

            fetch(process.env.REACT_APP_API_ENDPOINT + "/add-event", options)
                .then(data => data.json())
                .then(data => {
                    if (data?.status == 1) {
                        toast.success("Event added successfully");
                        setTimeout(() => { window.location.reload() }, 1000);
                    }
                    else toast.error(data?.msg || "An error occured");
                })
        }
    }

    //Dropdown
    const [clubNameAnchor, setClubNameAnchor] = useState(null);
    const clubNameOpen = Boolean(clubNameAnchor);

    return (
        <div className="modal" onClick={e => {
            if (e.currentTarget === e.target) {
                set(false);
                setIndex(null);
            }
        }}>
            <div className="modal-content">
                <button className="close" onClick={() => { set(false); setIndex(null); }}>✖</button>
                <h3>Record</h3>
                <div className='values'>
                    <div>
                        <p>Event Name<span>*</span></p>
                        <div>
                            <input placeholder="Jason" defaultValue={event?.event_name || ""} onChange={e => setEventData({ ...eventData, event_name: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Event Time<span>*</span></p>
                        <div>
                            <input placeholder="2023-12-07" value={eventData?.event_time || new Date().toISOString().split('T')[0]} onChange={e => setEventData({ ...eventData, event_time: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Club Name<span>*</span></p>
                        <div>
                            <Menu
                                anchorEl={clubNameAnchor}
                                open={clubNameOpen}
                                onClose={() => setClubNameAnchor(null)}
                                id="clubname-menu"
                                className="data-dropdown"
                                MenuListProps={{ 'aria-labelledby': 'clubname-button' }}
                                aria-labelledby="clubname-button"
                            >
                                {clubs.map((d, i) => {
                                    return <MenuItem key={i} onClick={() => { setClubNameAnchor(null); setEventData({ ...eventData, club_name: d }) }}>{d}</MenuItem>
                                })}
                            </Menu>
                            <Button
                                id="clubname-button"
                                aria-controls={clubNameOpen ? 'clubname-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={clubNameOpen ? 'true' : undefined}
                                onClick={(e) => setClubNameAnchor(e.currentTarget)}
                            >
                                <span>{eventData?.club_name || ""}</span>
                                <img src="/images/down.svg" />
                            </Button>
                        </div>
                    </div>
                    <div>
                        <p>Event Location</p>
                        <div>
                            <input placeholder="Jason" defaultValue={event?.event_location || ""} onChange={e => setEventData({ ...eventData, event_location: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Invite Letter</p>
                        <div>
                            <input placeholder="Jason" defaultValue={event?.invite_letter || ""} onChange={e => setEventData({ ...eventData, invite_letter: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Event Description</p>
                        <div>
                            <input placeholder="Jason" defaultValue={event?.event_description || ""} onChange={e => setEventData({ ...eventData, event_description: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Special Guest</p>
                        <div>
                            <input placeholder="Jason" defaultValue={event?.special_guests || ""} onChange={e => setEventData({ ...eventData, special_guests: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Event Sponsors</p>
                        <div>
                            <input placeholder="Jason" defaultValue={event?.event_sponsors || ""} onChange={e => setEventData({ ...eventData, event_sponsors: e.target.value })} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                </div>
                <button
                    className="update"
                    onClick={async () => {
                        if (event) await updateEvent();
                        else await addEvent();
                    }}
                >Update</button>
                
                {event && (
                    <>
                        <h3 style={{marginTop: "20px"}}>Event Attendees</h3>
                        <div>
                            {eventAttendees?.length == 0 && <span>No attendees so far</span>}
                            {eventAttendees?.map((a, i) => {
                                return <span key={i}>( {a?.first_name} {a?.last_name}, {a?.email} )</span>
                            })}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

function Modal2({ event, set, setIndex }) {

    const [memberSearch, setMemberSearch] = useState("");
    const [member, setMember] = useState({});
    const [step, setStep] = useState(1);

    async function registerEvent() {
        const options = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "access-token": localStorage.getItem("token")
            },
            body: JSON.stringify({
                name: event.event_name,
                member: memberSearch,
            })
        };

        fetch(process.env.REACT_APP_API_ENDPOINT + "/register-event", options)
            .then(data => data.json())
            .then(data => {
                if (data?.status == 1) {
                    toast.success("Member has been successfully registered to event.");
                    setTimeout(() => { window.location.reload() }, 1000);
                }
                else toast.error(data?.msg || "Member cannot be found, please enter valid information.");
                set(false);
                setIndex(null);
            })
    }

    async function nextStep() {
        fetch(process.env.REACT_APP_API_ENDPOINT + "/get-user-from-email-or-phone", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                data: memberSearch
            })
        })
            .then(data => data.json())
            .then(data => {
                if (data?.data) {
                    setMember(data?.data);
                    setStep(2);
                }
                else {
                    toast.error(data?.msg || "Member cannot be found, please enter valid information.");
                }
            })
    }

    return (
        <div className="modal" onClick={e => {
            if (e.currentTarget === e.target) {
                set(false);
                setIndex(null);
            }
        }}>
            <div className="modal-content">
                <button className="close" onClick={() => { set(false); setIndex(null); }}>✖</button>

                {step == 1 &&
                    <>
                        <h3>Register for an Event</h3>
                        <div className='values'>
                            <div>
                                <p>Member Email / Phone num.<span>*</span></p>
                                <div>
                                    <input style={{ minWidth: "200px" }} placeholder="admin@example.com" onChange={e => setMemberSearch(e.target.value)} />
                                    <img src="/images/pencil.svg" />
                                </div>
                            </div>
                        </div>
                        <button
                            className="update"
                            onClick={async () => {
                                if (event && memberSearch) await nextStep();
                            }}
                        >Next</button>
                    </>}

                {step == 2 &&
                    <>
                        <h3>Confirm Corresponding Member</h3>
                        <div className="user-data">
                            <p><b>Name: </b> {member.first_name + " " + member.last_name}</p>
                            <p><b>Phone Number: </b> {member?.phone_number || "-"}</p>
                            <p><b>Email: </b> {member?.email || "-"}</p>
                        </div>
                        <button
                            className="update"
                            onClick={async () => {
                                await registerEvent();
                            }}
                        >Update</button>
                    </>}
            </div>
        </div>
    )
}

export default function Events({ list, admin }) {

    const user = useSelector(state => state.user);

    const [datas, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [eventIndex, setEventIndex] = useState(null);
    const [lastMonthEvents, setLastMonthEvents] = useState(0);
    const [filter, setFilter] = useState("");

    useEffect(() => {
        const arr = [];
        for (let el of list) arr.push({
            name: el.event_name,
            time: new Date(parseInt(el.event_time)).toISOString().split('T')[0]
        });
        setData(arr);

        calculateLastMonthEvents();
    }, [list]);

    function calculateLastMonthEvents() {
        let count = 0;
        if (list) {
            for (let m of list) {
                let eventDate = new Date(parseInt(m.event_time)).getTime();
                let diff = Math.ceil((new Date().getTime() - eventDate) / (1000 * 60 * 60 * 24));
                if (diff >= 30 && diff < 60) count++;
            }
        }

        setLastMonthEvents(count);
    }

    function deleteEvent(index) {
        if (user) {
            const options = {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    "access-token": localStorage.getItem("token")
                },
                body: JSON.stringify({
                    sid: list[index].id
                })
            };

            fetch(process.env.REACT_APP_API_ENDPOINT + "/delete-event", options)
                .then(data => data.json())
                .then(data => {
                    if (data?.status == 1) {
                        toast.success("Event deleted successfully");
                        setTimeout(() => { window.location.reload() }, 1000);
                    }
                    else toast.error(data?.msg || "An error occured");
                })
        }
    }

    return (
        <div id="events">
            <div className="top">
                <div className="stat">
                    <div>
                        <p className="title">Total Events :</p>
                    </div>
                    <div>
                        <p className="value">{list?.length}</p>
                    </div>
                </div>
                <div className="stat">
                    <div>
                        <p className="title">Last Month :</p>
                    </div>
                    <div>
                        <p className="value">{lastMonthEvents}</p>
                    </div>
                </div>
                <div className="search">
                    <div>
                        <img src="/images/search.svg" />
                        <input placeholder="Search" onChange={e => setFilter(e.target.value)} />
                    </div>
                </div>
            </div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th></th> {/* Blank for number section in tbody */}
                            <th>Event Name</th>
                            <th>Event Time</th>
                            <th>
                                {admin &&
                                    <>
                                        <button onClick={() => setModal(true)}>Add New</button>
                                    </>
                                }
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {datas.map((d, i) => {
                            if (filter) {
                                if (!((i + 1) + d.name + d.time).toLowerCase().includes(filter.toLowerCase())) return <></>;
                            }
                            return (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{d.name}</td>
                                    <td>{d.time}</td>
                                    <td>
                                        <div>
                                            <button
                                                className="details"
                                                onClick={() => {
                                                    setEventIndex(i);
                                                    setModal2(true);
                                                }}>Register Member</button>
                                            <button
                                                className="details"
                                                onClick={() => {
                                                    setEventIndex(i);
                                                    setModal(true);
                                                }}
                                            >Details</button>
                                            <button
                                                className="trash"
                                                onClick={() => {
                                                    deleteEvent(i);
                                                }}
                                            >
                                                <img src="/images/trash.svg" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            {(modal && list?.[eventIndex]) &&
                <Modal
                    user={user}
                    set={setModal}
                    event={list[eventIndex]}
                    setIndex={setEventIndex}
                />
            }
            {(modal && !list?.[eventIndex]) && <Modal user={user} set={setModal} />}
            {(modal2 && list?.[eventIndex]) && <Modal2 event={list[eventIndex]} set={setModal2} setIndex={setEventIndex} />}
        </div>
    )
}