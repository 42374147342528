import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Clubs from './clubs';
import Members from './members';
import Events from './events';
import Staff from './staff';
import Partners from './partners';
import Services from './services';
import ServiceTracking from './services-tracking';
import Transactions from './transactions';
import DashboardHeader from '../../components/dashboard-header';
import DashboardNav from '../../components/dashboard-nav';
import './dashboard.scss';

export default function Dashboard() {

    const location = useLocation();
    const user = useSelector(state => state.user);
    const [lists, setLists] = useState({});

    useEffect(() => {
        if (user) {
            //Get lists
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    "access-token": localStorage.getItem("token")
                }
            };
    
            fetch(process.env.REACT_APP_API_ENDPOINT + "/get-lists", options)
            .then(data => data.json())
            .then(data => {
                setLists(data);
            })
        }
    }, []);

    if (!user) return <></>;
    return (
        <div id="dashboard">
            <DashboardNav path={location.pathname} role={lists.role} />
            <div className="content">
                <DashboardHeader path={location.pathname} />
                <div className='container'>
                    {/* Dashboard Routes */}
                    { location.pathname !== "/dashboard" ? (
                        <Routes>
                            {lists.role == "admin" && (
                                <>
                                <Route path="/clubs" element={<Clubs admin={user} list={lists?.clubs || []} />} />
                                <Route path="/members" element={<Members admin={user} list={lists?.members || []} />} />
                                <Route path="/events" element={<Events admin={user} list={lists?.events || []} />} />
                                <Route path="/staff" element={<Staff admin={user} list={lists?.staff || []} />} />
                                <Route path="/partners" element={<Partners admin={user} list={lists?.partners || []} />} />
                                <Route path="/services" element={<Services admin={user} list={lists?.services || []} />} />
                                <Route path="/service-tracking" element={<ServiceTracking admin={user} list={lists?.service_tracking || []} />} />
                                <Route path="/transactions" element={<Transactions admin={user} list={lists?.transactions || []} />} />
                                </>
                            )}
                            {lists.role == "staff" && (
                                <>
                                <Route path="/members" element={<Members admin={user} list={lists?.members || []} role="staff" />} />
                                <Route path="/service-tracking" element={<ServiceTracking admin={user} list={lists?.service_tracking || []} role="staff" />} />
                                <Route path="/transactions" element={<Transactions admin={user} list={lists?.transactions || []} role="staff" />} />
                                </>
                            )}
                        </Routes>
                    ) : (
                        <div className="stats">
                            <div className="stat">
                                <div>
                                    <p>Total Members : </p>
                                    <h2>{lists?.members?.length || 0}</h2>
                                </div>
                                <div>
                                    <img src="/images/vector-1.png" />
                                </div>
                            </div>
                            <div className="stat">
                                <div>
                                    <p>Total Staff : </p>
                                    <h2>{lists?.staff?.length || 0}</h2>
                                </div>
                                <div>
                                    <img src="/images/vector-2.png" />
                                </div>
                            </div>
                            <div className="stat">
                                <div>
                                    <p>Events : </p>
                                    <h2>{lists?.events?.length || 0}</h2>
                                </div>
                                <div>
                                    <img src="/images/vector-3.png" />
                                </div>
                            </div>
                            <div className="stat">
                                <div>
                                    <p>Services : </p>
                                    <h2>{lists?.services?.length || 0}</h2>
                                </div>
                                <div>
                                    <img src="/images/vector-4.png" />
                                </div>
                            </div>
                            <div className="stat">
                                <div>
                                    <p>Total Partners : </p>
                                    <h2>{lists?.partners?.length || 0}</h2>
                                </div>
                                <div>
                                    <img src="/images/vector-5.png" />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}