import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

function Modal({ user, set, service, setIndex = () => {} }) {

    const [servicesData, setServicesData] = useState(service || {});
    const [partners, setPartners] = useState([]);

    useEffect(() => {
        if (service) {
            //Make dates valid
            let start = null;
            if (servicesData?.date) {
                start = servicesData.date;
                try {
                    start = new Date(parseInt(start)).toISOString().split('T')[0];
                } catch(e) {
                    start = null;
                }
            }

            setServicesData({...servicesData, date: start});
        }

        const options = {
            method: "GET",
            headers: {
                'Content-Type': 'application/json',
            }
        };

        fetch(process.env.REACT_APP_API_ENDPOINT + "/get-partners", options)
        .then(data => data.json())
        .then(data => {
            if (data?.status == 0) setPartners([]);
            else setPartners(data?.data);
        });
    }, []);

    async function updateServices() {
        if (user) {
            let data = {...servicesData};

            //Validate integers
            try {
                if (data["commission_ratio"]) data["commission_ratio"] = parseInt(data["commission_ratio"]);
            } catch(e) {
                toast.error("Please enter a valid decimal for commission ratio");
                return;
            }

            const options = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    "access-token": localStorage.getItem("token")
                },
                body: JSON.stringify({
                    sid: data.id,
                    new: data
                })
            };
    
            fetch(process.env.REACT_APP_API_ENDPOINT + "/update-services", options)
            .then(data => data.json())
            .then(data => {
                if (data?.status == 1) {
                    toast.success("Service updated successfully");
                    setTimeout(() => { window.location.reload() }, 1000);
                }
                else toast.error("An error occured");
            })
        }
    }

    async function addServices() {
        if (user) {
            let data = {...servicesData};
            data["date"] = new Date().getTime().toString();

            //Validate integers
            try {
                if (data["commission_ratio"]) data["commission_ratio"] = parseInt(data["commission_ratio"]);
            } catch(e) {
                toast.error("Please enter a valid decimal for commission ratio");
                return;
            }

            const options = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    "access-token": localStorage.getItem("token")
                },
                body: JSON.stringify({
                    sid: data.id,
                    data: data
                })
            };
    
            fetch(process.env.REACT_APP_API_ENDPOINT + "/add-services", options)
            .then(data => data.json())
            .then(data => {
                if (data?.status == 1) {
                    toast.success("Service added successfully");
                    setTimeout(() => { window.location.reload() }, 1000);
                }
                else toast.error(data?.msg || "An error occured");
            })
        }
    }

    //Dropdown
    const [anchor, setAnchor] = useState(null);
    const open = Boolean(anchor);

    return (
        <div className="modal" onClick={e => {
            if (e.currentTarget === e.target) {
                set(false);
                setIndex(null);
            }
        }}>
            <div className="modal-content">
                <button className="close" onClick={() => { set(false); setIndex(null); }}>✖</button>
                <h3>Record</h3>
                <div className='values center'>
                    <div>
                        <p>Service Name<span>*</span></p>
                        <div>
                            <input placeholder="Jason" defaultValue={service?.service_name || ""} onChange={e => setServicesData({...servicesData, service_name: e.target.value})} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Service Provider</p>
                        <div>
                            <Menu
                                anchorEl={anchor}
                                open={open}
                                onClose={() => setAnchor(null)}
                                id="menu"
                                className="data-dropdown"
                                MenuListProps={{ 'aria-labelledby': 'button' }}
                                aria-labelledby="button"
                            >
                                {partners.map((p, i) => {
                                    return (
                                        <MenuItem key={i} onClick={() => { setAnchor(null); setServicesData({ ...servicesData, service_provider: p }) }}>{p}</MenuItem>
                                    )
                                })}
                            </Menu>
                            <Button
                                id="button"
                                aria-controls={open ? 'menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={(e) => setAnchor(e.currentTarget)}
                            >
                                <span>{servicesData?.service_provider || ""}</span>
                                <img src="/images/down.svg" />
                            </Button>
                        </div>
                    </div>
                    <div>
                        <p>Description</p>
                        <div>
                            <input placeholder="Jason" defaultValue={service?.description || ""} onChange={e => setServicesData({...servicesData, description: e.target.value})} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Commission ratio</p>
                        <div>
                            <input placeholder="10%" defaultValue={service?.commission_ratio || ""} onChange={e => setServicesData({...servicesData, commission_ratio: e.target.value})} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                </div>
                <button
                    className="update"
                    onClick={async () => {
                        if (service) await updateServices();
                        else await addServices();
                    }}
                >Update</button>
            </div>
        </div>
    )
}

export default function Services({ list, admin }) {

    const user = useSelector(state => state.user);

    const [datas, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [serviceIndex, setServiceIndex] = useState(null);
    const [lastMonthServices, setLastMonthServices] = useState(0);
    const [filter, setFilter] = useState("");

    useEffect(() => {
        const arr = [];
        for (let el of list) arr.push({
            name: el.service_name,
            provider: el.service_provider,
            desc: el.description,
            commission: el.commission_ratio
        });
        setData(arr);

        calculateLastMonthServices();
    }, [list]);

    function calculateLastMonthServices() {
        let count = 0;
        if (list) {
            for (let m of list) {
                let serviceDate = new Date(parseInt(m.date)).getTime();
                let diff = Math.ceil((new Date().getTime() - serviceDate) / (1000 * 60 * 60 * 24));
                if (diff >= 30 && diff < 60) count++;
            }
        }

        setLastMonthServices(count);
    }

    function deleteService(index) {
        if (user) {
            const options = {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    "access-token": localStorage.getItem("token")
                },
                body: JSON.stringify({
                    sid: list[index].id
                })
            };
    
            fetch(process.env.REACT_APP_API_ENDPOINT + "/delete-services", options)
            .then(data => data.json())
            .then(data => {
                if (data?.status == 1) {
                    toast.success("Service deleted successfully");
                    setTimeout(() => { window.location.reload() }, 1000);
                }
                else toast.error(data?.msg || "An error occured");
            })
        }
    }

    return (
        <div id="services">
            <div className="top">
                <div className="stat">
                    <div>
                        <p className="title">Total Services :</p>
                    </div>
                    <div>
                        <p className="value">{list?.length}</p>
                    </div>
                </div>
                <div className="stat">
                    <div>
                        <p className="title">Last Month :</p>
                    </div>
                    <div>
                        <p className="value">{lastMonthServices}</p>
                    </div>
                </div>
                <div className="search">
                    <div>
                        <img src="/images/search.svg" />
                        <input placeholder="Search" onChange={e => setFilter(e.target.value)} />
                    </div>
                </div>
            </div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th></th> {/* Blank for number section in tbody */}
                            <th>Name</th>
                            <th>Service Provider</th>
                            <th>Description</th>
                            <th>Comission</th>
                            <th>
                                { admin &&
                                    <>
                                        <button onClick={() => setModal(true)}>Add New</button>
                                    </>
                                }
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {datas.map((d, i) => {
                            if (filter) {
                                if (!((i + 1) + " " + d.name + d.provider + d.desc + d.commission).toLowerCase().includes(filter.toLowerCase())) return <></>;
                            }
                            return (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{d.name}</td>
                                    <td>{d.provider}</td>
                                    <td>{d.desc}</td>
                                    <td>%{d.commission}</td>
                                    <td>
                                        <div>
                                            <button
                                                className="details"
                                                onClick={() => {
                                                    setServiceIndex(i);
                                                    setModal(true);
                                                }}
                                            >Update</button>
                                            <button 
                                                className="trash"
                                                onClick={() => {
                                                    deleteService(i);
                                                }}
                                            >
                                                <img src="/images/trash.svg" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            { (modal && list?.[serviceIndex]) &&
                <Modal
                    user={user}
                    set={setModal}
                    service={list[serviceIndex]}
                    setIndex={setServiceIndex}
                />
            }
            { (modal && !list?.[serviceIndex]) && <Modal user={user} set={setModal} /> }
        </div>
    )
}