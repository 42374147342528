import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './header.scss';

export default function DashboardHeader({ path }) {

    function logout() {
        localStorage.removeItem("token");
        window.location.reload();
    }

    const [title, setTitle] = useState("Dashboard");

    useEffect(() => {
        switch (path) {
            case "/dashboard/members":
                setTitle("Members");
                break;
            case "/dashboard/events":
                setTitle("Events");
                break;
            case "/dashboard/staff":
                setTitle("Staff");
                break;
            case "/dashboard/partners":
                setTitle("Partners");
                break;
            case "/dashboard/services":
                setTitle("Services");
                break;
            default:
                setTitle("Dashboard");
        }
    }, [path]);

    return (
        <header id="dashboard-header">
            <h1>{title}</h1>
            <div>
                <img className='logout' src="/images/logout.png" onClick={logout} />
                <img className='bell' src="/images/bell.svg" />
                <img className="pfp" src="/images/example-pfp.png" />
            </div>
        </header>
    )
}