import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';

const options = {
    method: "GET",
    headers: {
        'Content-Type': 'application/json',
    }
};

function Modal({ user, set, club, setIndex = () => {} }) {

    const [clubData, setClubData] = useState(club || {});
    const [staff, setStaff] = useState([]);
    const [admins, setAdmins] = useState([]);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_ENDPOINT + "/get-admins", options)
            .then(data => data.json())
            .then(data => {
                setAdmins(data.data);
            });

        fetch(process.env.REACT_APP_API_ENDPOINT + "/get-staff", options)
            .then(data => data.json())
            .then(data => {
                setStaff(data.data);
            });
    }, []);

    async function updateClub() {
        if (user) {
            let data = {...clubData};

            const options = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    "access-token": localStorage.getItem("token")
                },
                body: JSON.stringify({
                    sid: data.id,
                    new: data
                })
            };
    
            fetch(process.env.REACT_APP_API_ENDPOINT + "/update-club", options)
            .then(data => data.json())
            .then(data => {
                if (data?.status == 1) {
                    toast.success("Club updated successfully");
                    setTimeout(() => { window.location.reload() }, 1000);
                }
                else toast.error("An error occured");
            })
        }
    }

    async function addClub() {
        if (user) {
            let data = {...clubData};

            const options = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    "access-token": localStorage.getItem("token")
                },
                body: JSON.stringify({
                    sid: data.id,
                    data: data
                })
            };
    
            fetch(process.env.REACT_APP_API_ENDPOINT + "/add-club", options)
            .then(data => data.json())
            .then(data => {
                if (data?.status == 1) {
                    toast.success("Club added successfully");
                    setTimeout(() => { window.location.reload() }, 1000);
                }
                else toast.error(data?.msg || "An error occured");
            });
        }
    }

    const [anchor, setAnchor] = useState(null);
    const open = Boolean(anchor);

    return (
        <div className="modal" onClick={e => {
            if (e.currentTarget === e.target) {
                set(false);
                setIndex(null);
            }
        }}>
            <div className="modal-content">
                <button className="close" onClick={() => { set(false); setIndex(null); }}>✖</button>
                <h3>Record</h3>
                <div className='values center'>
                    <div>
                        <p>Club Name<span>*</span></p>
                        <div>
                            <input placeholder="Jason" defaultValue={club?.club_name || ""} onChange={e => setClubData({...clubData, club_name: e.target.value})} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                    <div>
                        <p>Club Owner<span>*</span></p>
                        <div>
                            <Menu
                                anchorEl={anchor}
                                open={open}
                                onClose={() => setAnchor(null)}
                                id="owner-menu"
                                className="data-dropdown"
                                MenuListProps={{ 'aria-labelledby': 'owner-button' }}
                                aria-labelledby="owner-button"
                            >
                                {admins.map((obj, i) => {
                                    return <MenuItem key={i} onClick={() => { setAnchor(null); setClubData({ ...clubData, owner_sid: obj.id, owner_name: obj.name }) }}>{obj.name}</MenuItem>
                                })}
                                {staff.map((obj, i) => {
                                    return <MenuItem key={i} onClick={() => { setAnchor(null); setClubData({ ...clubData, owner_sid: obj.id, owner_name: obj.name }) }}>{obj.name}</MenuItem>
                                })}
                            </Menu>
                            <Button
                                id="owner-button"
                                aria-controls={open ? 'owner-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={(e) => setAnchor(e.currentTarget)}
                            >
                                <span>{clubData?.owner_name || ""}</span>
                                <img src="/images/down.svg" />
                            </Button>
                        </div>
                    </div>
                    <div>
                        <p>Description</p>
                        <div>
                            <input placeholder="Jason" defaultValue={club?.club_description || ""} onChange={e => setClubData({...clubData, club_description: e.target.value})} />
                            <img src="/images/pencil.svg" />
                        </div>
                    </div>
                </div>
                <button
                    className="update"
                    onClick={async () => {
                        if (club) await updateClub();
                        else await addClub();
                    }}
                >Update</button>
            </div>
        </div>
    )
}

export default function Clubs({ list, admin }) {

    const user = useSelector(state => state.user);

    const [datas, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [clubIndex, setClubIndex] = useState(null);
    const [filter, setFilter] = useState("");

    useEffect(() => {
        const arr = [];

        for (let el of list) arr.push({
            name: el.club_name,
            owner: el.owner_name,
            desc: el?.club_description || ""
        });

        setData(arr);
    }, [list]);

    function deleteClub(index) {
        if (user) {
            const options = {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    "access-token": localStorage.getItem("token")
                },
                body: JSON.stringify({
                    sid: list[index].id
                })
            };
    
            fetch(process.env.REACT_APP_API_ENDPOINT + "/delete-club", options)
            .then(data => data.json())
            .then(data => {
                if (data?.status == 1) {
                    toast.success("Club deleted successfully");
                    setTimeout(() => { window.location.reload() }, 1000);
                }
                else toast.error(data?.msg || "An error occured");
            })
        }
    }

    return (
        <div id="services">
            <div className="top">
                <div className="stat">
                    <div>
                        <p className="title">Total Clubs :</p>
                    </div>
                    <div>
                        <p className="value">{list?.length}</p>
                    </div>
                </div>
                <div className="search">
                    <div>
                        <img src="/images/search.svg" />
                        <input placeholder="Search" onChange={e => setFilter(e.target.value)} />
                    </div>
                </div>
            </div>
            <div className="table-container">
                <table>
                    <thead>
                        <tr>
                            <th></th> {/* Blank for number section in tbody */}
                            <th>Name</th>
                            <th>Owner</th>
                            <th>Description</th>
                            <th>
                                { admin &&
                                    <>
                                        <button onClick={() => setModal(true)}>Add New</button>
                                    </>
                                }
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {datas.map((d, i) => {
                            if (filter) {
                                if (!((i + 1) + " " + d.name + d.owner + d.desc).toLowerCase().includes(filter.toLowerCase())) return <></>;
                            }
                            return (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td>{d.name}</td>
                                    <td>{d.owner}</td>
                                    <td><p>{d.desc}</p></td>
                                    <td>
                                        <div>
                                            <button
                                                className="details"
                                                onClick={() => {
                                                    setClubIndex(i);
                                                    setModal(true);
                                                }}
                                            >Update</button>
                                            <button 
                                                className="trash"
                                                onClick={() => {
                                                    deleteClub(i);
                                                }}
                                            >
                                                <img src="/images/trash.svg" />
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            { (modal && list?.[clubIndex]) &&
                <Modal
                    user={user}
                    set={setModal}
                    club={list[clubIndex]}
                    setIndex={setClubIndex}
                />
            }
            { (modal && !list?.[clubIndex]) && <Modal user={user} set={setModal} /> }
        </div>
    )
}