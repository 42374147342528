import { useState } from "react";
import { Link } from "react-router-dom";
import './login.scss';

export default function Login() {

    const [remember, setRemember] = useState(false);
    const [email, setEmail] = useState(localStorage.getItem("email-input") || "");
    const [password, setPassword] = useState(localStorage.getItem("password-input") || "");
    const [err, setErr] = useState(null);
    const [suc, setSuc] = useState(null);

    function login(e) {
        e.preventDefault();

        if (remember) rememberMe();
        else {
            localStorage.removeItem("email-input");
            localStorage.removeItem("password-input");
        }

        const options = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                password: password
            })
        };

        fetch(process.env.REACT_APP_API_ENDPOINT + "/login", options)
        .then(data => data.json())
        .then(data => {
            if (data.status === 0) setErr(data.msg);
            else if (data.status === 1) {
                const token = data.token;
                if (token) {
                    setSuc(data.msg);
                    localStorage.setItem("token", data.token);
                    window.location.href = "/dashboard";
                } else setErr("An error occured");
            }
        })
    }

    function rememberMe() {
        localStorage.setItem("email-input", email);
        localStorage.setItem("password-input", password);
    }

    return (
        <div id="login">
            <div className="form-container">
                <h1>Admin Login</h1>
                <form>
                    <h2>Login</h2>
                    { err ? <span className="error">{err}</span> : ""}
                    { suc ? <span className="success">{suc}</span> : ""}
                    <input defaultValue={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder="Email" />
                    <input defaultValue={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" />
                    <div>
                        <div>
                            <input onChange={() => setRemember(!remember)} type="checkbox" />
                            <span>Remember me</span>
                        </div>
                        <div>
                            <Link to="#">Forgotten Password?</Link>
                        </div>
                    </div>
                    <button className="login-button" onClick={login}>Login</button>
                </form>
                <div className="register-button">
                    <p>Not registered yet? <Link to="/signup">Create an Account</Link></p>
                </div>
            </div>
            <div className="image-container">
                <img src="/images/login-bg.svg" className="bg" />
                <div>
                    <img src="/images/blob.svg" className="blob" />
                    <img src="/images/login-vector.svg" className="vector" />
                </div>
            </div>
        </div>
    )
}